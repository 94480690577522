/*--------------------- LOGIN -------------------*/

.ladiui.auth .auth-left {
  float: left;
  width: 50%;
}

.ladiui.auth .auth-right {
  width: 50%;
  float: right;
}

.ladiui.auth .auth-left iframe {
  width: 100%;
  border: 0px;
  height: 100vh;
}

.ladiui.auth {
  background: url(https://builder.ladipage.vn/images/geometry2.png) repeat;
  color: #44566c;
  flex-wrap: wrap;
  display: flex;
  height: 100vh;
}

#modal-register-warning .ladiui.modal-body {
  text-align: center;
  margin-bottom: unset !important;
}

#modal-register-warning img {
  height: 150px;
  margin-bottom: 24px;
}

#modal-register-warning .ladiui.modal-footer {
  justify-content: center;
  padding: 0px 0px 24px 0px;
}

#modal-register-warning .modal-content {
  width: 460px !important;
  height: 400px !important;
  border-radius: 8px;
}

#modal-register-warning .ladiui.modal-close {

  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  right: 14px !important;

}

#modal-register-warning .ladiui.modal-close i {

  position: relative;
  bottom: 3px;
  width: 8px;
  height: 8px;
  background: white !important;
}


.full-width {
  width: 100%;
}

.ladiui.auth h1.form-title {
  margin: 0px 0px 24px 0px;
  padding: 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #202020;
}

.user-field {
  width: 48%;
}

.flex {
  display: flex;
}

.create-user-form .ladiui-form-group.flex {
  justify-content: space-between;
}

.ladiui.auth p.form-sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #6d6d6d;
  /* margin-bottom: 10px; */
}

.ladiui.auth .ladiui-login-wrap.no-frame {
  width: 100%;
}

.ladiui.auth .ladiui-login-wrap {
  /* width: 50%; */
  width: calc(100% - 530px);
  background-color: #f2f2f2;


}

.ladiui.auth .auth-form-content {
  margin-top: 96px;
}

.ladiui.auth .ladiui.form-control {
  height: 40px;
  border-radius: 4px;
}

.ladiui.auth .ladiui.form-control.error {
  border-color: rgba(236, 93, 79, 1);
  /* background: rgba(255, 243, 237, 1); */
}

.ladiui.auth .ladiui.title-text-form-lever2 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: rgba(86, 101, 119, 1);

}

.ladiui.auth .ladiui-login-wrap .form-title {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}


.ladiui.auth .ladiui-iframe {
  /* width: 50%; */
  width: 530px;
  background: #f1f3f4;
}

.ladiui.auth.redirect-delay .ladiui-iframe {
  width: 100%;
}

.ladiui.auth .ladiui-login {
  width: 400px;
  margin: auto;

  position: relative;
  background: white;
  height: auto;
  padding: 5px 32px 32px 32px;
  border-radius: 8px;
}

.ladiui.auth .ladiui-login .ladiui.btn-primary {
  font-weight: 400;
}

.ladiui.auth .ladiui-logo {
  padding: 0px;
  border: 0px;
  margin: 58px 0 24px 0;
  text-align: center;
}

.ladiui.auth .ladiui-group {
  margin: 20px 0 50px 0;
  display: flex;
  gap: 20px;
}

.ladiui.auth .ladiui-group img {
  width: 70.86px;
  height: 20px;
}

.ladiui.auth .ladiui-logo img {
  width: 165px;
}

.ladiui.auth .ladiui-login-form {
  /* width: 540px; */
  margin: auto;
}

.ladiui.auth .create-user-form .ladiui-form-group {
  margin-top: 20px;
  position: relative;

}

.ladiui.auth .create-user-form.signup .ladiui-form-group {}

.create-user-form .btn-primary {
  border-radius: 4px;
  font-weight: 400;
  height: 40px;
}

.ladiui.auth .ladiui-login-form .ladiui-form-group .ladiui-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #202020;
  margin: 0 0 8px 0;
  padding: 0;
}

.ladiui.auth .ladiui-login-form .button {
  width: 100%;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 2px;
}

.ladiui.auth .social-login {
  margin-top: 16px;
  margin-bottom: 24px;
  text-align: center;
}

.ladiui.auth .social-login .social-button {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.ladiui.auth .social-login a.login-facebook {
  /* background: #0866FF; */
}

.ladiui.auth .social-login a.login-google {
  /* background: #DE4B39; */
}

.ladiui.auth .social-login a.login-facebook,
.ladiui.auth .social-login a.login-google {
  height: 44px;
  display: inline-flex;
  justify-content: center;
  width: 160px;
  font-size: 14px;
  line-height: 24px;
  /* font-weight: 500; */
  padding: 10px;
  margin: 2px;
  color: rgba(14, 35, 60, 1);
  /* background: transparent; */
  border: 1px solid rgba(207, 211, 216, 1);
  border-radius: 4px;
}

.ladiui.auth .forgot-password {
  margin-top: 4px;
}

.ladiui.auth .forgot-password a {

  color: rgba(60, 114, 249, 1);
  font-size: 11px;
}

.ladiui.auth .password-box {
  margin: 8px 0 24px 0px !important;
  overflow: hidden;
}

.ladiui.auth span.login-with {
  display: block;
  position: relative;
  margin: 24px 0;
  color: rgba(159, 167, 177, 1);
}

.ladiui.auth span.login-with h1 {
  font-family: sans-serif;
  text-align: center;
  max-width: 600px;
  position: relative;
  font-size: 12px;
}

.ladiui.auth span.login-with h1:before {
  content: "";
  display: block;
  width: 24px;
  height: 1px;
  background: #E7E9EB;
  left: 72px;
  top: 50%;
  position: absolute;
}

.ladiui.auth span.login-with:after {
  content: "";
  display: block;
  width: 24px;
  height: 1px;
  background: #E7E9EB;
  right: 72px;
  top: 50%;
  position: absolute;
}

.ladiui.auth span.login-with span {
  margin: 0px auto;
  background: #fff;
  padding: 0px 5px;
  position: relative;
}

.ladiui.auth .question-form {
  width: 100%;
  text-align: center;
  margin-top: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d6d6d;
}


.ladiui.auth .suggest-form {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d6d6d;
}

.ladiui.auth .ladipage-powered {
  display: flex;
  gap: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin: 100px 0 16px 0;
  width: 340px;
  justify-content: center;
  align-items: center;
}

.ladiui.auth .ladipage-powered img {
  width: 58px;
  height: 16px;
}

.ladiui.auth .question-form a {
  color: inherit;
  font-weight: 500;
  color: rgba(28, 0, 194, 1);
}

.language .ladiui.dropdown-menu {
  bottom: 30px;
  right: 0px;
}

.language .ladiui.dropdown-item {
  display: inline-flex;
  width: 140px;
}

.ladiui.auth .ladipage-terms {
  justify-content: center;
  margin-top: 56px;
  margin-bottom: 56px;
  text-align: center;
  display: inline-flex;
  justify-content: space-between;
  width: 550px;
  padding: 0px 20px;
}

.ladiui.auth .ladipage-terms ul {
  margin: 0px;
  padding: 0px;
}

.ladiui.auth .ladipage-terms ul li {
  display: inline-block;
}

.ladiui.auth .ladipage-terms ul li a {
  color: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6d6d6d;
}

.ladiui.auth .ladipage-terms ul li a.terms-item {
  margin: 0 12px;
  border-right: 1px solid #c5c5c5;
  border-left: 1px solid #c5c5c5;
  padding: 0 12px;
}

.ladiui.auth .ladipage-terms ul li a:hover {
  text-decoration: underline;
}

.ladiui.table .ladiui-navbar.-horizontal li>ul {
  right: 0px;
}

.ladiui.table thead tr th {
  position: relative;
}

.ladiui.table thead tr th.sorting {
  cursor: pointer;
}

.ladiui.table thead tr th.sorting.up::after {
  content: "\ea47";
  font-family: "LadiIcons";
  font-size: 10px;
  top: 20px;
  position: absolute;
  z-index: 0;
  color: #8697a8;
  padding-left: 6px;
}

.ladiui.table thead tr th.sorting::after,
.ladiui.table thead tr th.sorting.down::after {
  content: "\ea46";
  font-family: "LadiIcons";
  font-size: 8px;
  top: 21px;
  position: absolute;
  z-index: 0;
  color: #8697a8;
  padding-left: 6px;
}

.ladiui-topbar {
  height: 64px;
  border-bottom: 1px solid var(--main-line);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--main-white);
  position: fixed;
  width: 100%;
  z-index: 10;
}

.ladiui-topbar .topbar-left {
  position: relative;
  left: 0px;
}

/* .ladiui-content {
  padding-top: 70px;
  display: grid;
  position: relative;
} */

.ladiui-order-right .order-customer {
  position: relative;
}

.order-head-right {
  position: absolute;
  top: 70px;
  right: 33px;
}

.order-head-right a {
  color: inherit;
}

.order-customer-edit {
  display: block;
  position: absolute;
  top: 10px;
  right: 15px;
  color: #0763db;
}

.row-actions>li>a {
  line-height: inherit;
}

.page-title {
  padding: 0px 0 24px 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.pagination-block .per-page ul {
  bottom: 50px;
  top: inherit;
}

.ladiui-search-custom::after {
  content: "\ea41";
  font-family: "LadiIcons";
  font-size: 18px;
  display: block;
  z-index: 9999;
  position: absolute;
  right: 0px;
  padding-right: 10px;
  line-height: 40px;
  top: 0px;
  cursor: pointer;
  z-index: 2;
}

.ladipage-account {
  font-size: 14px;
}

.ladipage-account .ladipage-account-avatar {
  max-height: 64px;
}

.ladipage-account .ladipage-account-avatar img {
  width: 64px;
  height: 64px;
}

.ladipage-account {
  padding: 24px 24px 0 24px !important;
}

.ladipage-account .ladipage-account-content {
  display: flex !important;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid var(--main-line);
  padding: 0px 0px 24px 0;
  margin: 0 0 12px 0;
}

.ladipage-account-info>span {
  display: block;
  line-height: 24px;
}

.ladipage-account-name {
  font-weight: 500;
}

.ladipage-account-email {
  color: var(--main-secondary);
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
}

.ladipage-account-signout {
  margin: 0 0 12px 0 !important;
}

.ladipage-account-signout a {
  font-size: 14px;
  line-height: 15px !important;
  word-wrap: break-word;
  border-radius: 2px;
  color: var(--main-black) !important;
  display: block;
  padding: 12px 21px !important;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
}

.ladipage-account-button {
  list-style: none;
}

.ladipage-account-button .ladiui-link {
  word-wrap: break-word;
  border-radius: 2px;
  color: var(--main-black) !important;
  display: block;
  padding: 12px 21px !important;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
  font-size: 14px;
  line-height: 15px;
}

.ladipage-account-button .ladiui {
  width: 16px;
  height: 16px;
}


.ladiui.icon.new-ldicon-user-circle-fill {
  -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-circle-fill.svg) no-repeat 50% 50%;
  mask: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-circle-fill.svg) no-repeat 50% 50%;
}

.ladiui.icon.ldicon-logo-google {
  -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-_google.svg) no-repeat 50% 50%;
  mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-_google.svg) no-repeat 50% 50%;
}


.ladiui.icon.ldicon-logo-facebook {
  -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-facebook.svg) no-repeat 50% 50%;
  mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-facebook.svg) no-repeat 50% 50%;
}

.logo-google {
  width: 200px;
  /* Kích thước logo */
  height: 80px;
  background-image: linear-gradient(90deg, #4285F4, #34A853, #FBBC05, #EA4335);
  /* Màu sắc của Google */
  background-size: cover;
  background-clip: text;
  /* Đảm bảo chỉ hiển thị trong phần tử */
  color: transparent;
  /* Ẩn văn bản nếu có */
  -webkit-mask-image: url('https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-_google.svg');
  mask-image: url('https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-_google.svg');
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;

}

.ladiui.icon.new-ldicon-user-circle-fill {
  background-color: #2B57D6;
  box-sizing: unset;
  display: inline-block;
  width: 24px;
  height: 24px;
  mask-size: 16px;
  pointer-events: none;
  position: relative;
  top: 6px;
}

.ladiui.icon.ldicon-logo-google,
.ladiui.icon.ldicon-logo-facebook {
  background-color: white;
  box-sizing: unset;
  display: inline-block;
  width: 24px;
  height: 24px;
  pointer-events: none;
  position: relative;
  margin-right: 4px;
  ;
}

.suggest-form a {
  color: rgba(28, 0, 194, 1);
  font-weight: 500;
  font-family: "Roboto";
}

.col-50 {
  width: 48%;
}

.ladiui-form-group.recapcha {
  display: flex !important;
  justify-content: center !important;
}

#close-login-frame {
  background: rgb(0 0 0 / 40%);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 4px 4px;
}

#close-login-frame i {
  background: white;
}

.ladiui.auth.layout-2,
.ladiui.auth.layout-3 {
  justify-content: center;

}

.ladiui.auth.layout-2 {
  background: #0000005c;
}

.ladiui.auth.layout-2 .ladiui-login-wrap {
  width: unset !important;
  padding: 0px 24px;
  height: 850px;
  background: unset;
}

.ladiui.auth.layout-2 .ladiui-login {
  margin-top: 120px;
  background: white;
  padding: 24px;
  width: 600px;
}

.mt-20 {
  margin-top: 20px;
}

.resend-opt span {
  color: rgba(28, 0, 194, 1);
}

.resend-opt span.disable-resend {
  color: rgba(28, 0, 194, 0.5);
}

.resend-opt {
  position: absolute;
  right: 8px;
  bottom: 6px;
  cursor: pointer;
}

.error-input.is_error {
  color: rgba(192, 19, 34, 1);
  margin-top: 4px;
  font-size: 11px;
}