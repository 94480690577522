body {
    /* background: #fff; */
    color: #202124;

    direction: ltr;
    font-size: 15px;
    line-height: 1.4286;
    margin: 0;
    padding: 0;
    font-family: Roboto, 'Noto Sans Myanmar UI', arial, sans-serif;
}

.ladiui.input {
    box-shadow: none;
}

.ladiui-checkout-container {
    max-width: 1200px;
    margin: 0px auto;
}

.ladiui-checkout-header {
    box-shadow: 0 0px 1px 0 rgba(0, 0, 0, .2);
    overflow: hidden;
    padding: 10px 0px;
    background: #f8fafb;
}

.ladiui-checkout-body {
    clear: both;
    display: grid;
}

.ladiui-checkout-logo {
    width: 140px;
    margin: 15px 70px 0px 0px;
    float: left;
}

.ladiui-checkout-logo img {
    width: 100%;
}

.ladiui-checkout-step .ladiui-tabs {
    margin-top: 9px;
}

.ladiui-checkout-body-left {
    width: 60%;
    float: left;
    height: 100vh;
    min-width: 700px;
}

.checkout-heading {
    margin-bottom: 20px;
    font-weight: 700;
    background: #fff;
    position: relative;
    width: max-content;
    text-transform: uppercase;
    font-size: 14px;
}

.float-label {
    position: relative;
}

.ladiui-checkout-body-left #tab-customer-info label {
    margin: 5px 0px;
    display: inline-block;
    font-weight: 300;
    background: #fff;
    position: absolute;
    top: -15px;
    left: 11px;
    font-size: 14px;
    z-index: 5;
    padding: 0px 5px;
}

.ladiui-checkout .ladiui.input {
    height: 50px;
    border: 1px solid #dadce0;
}

.ladiui-checkout-body-left .form-group {
    margin-bottom: 25px;
}

.ladiui-checkout-body .ladiui-tab-content {
    max-height: inherit;
    padding-left: 0px;
    padding-right: 0px;
    clear: both;
    overflow: inherit;
}

.account-details,
.billing-details,
.payment-details {
    margin: 15px auto;
    background: #fff;
    border-radius: 4px;
}

.zipcode {
    margin-right: 0px;
}

.state {
    margin-right: 25px;
    width: 43.333333%;
}

.state:last-child {
    margin-right: 0px;
}

.ladiui-checkout-body-right {
    width: 40%;
    float: right;
    background: #fafafa;
    height: 100vh;
    border-left: 1px solid #efefef;
    position: absolute;
    right: 0px;
    min-width: 500px;
}

.ladiui-checkout-items {
    margin-top: 30px;
    border-radius: 4px;
}

.ladiui-check-item-image {
    float: left;
    width: 45px;
    height: 45px;
    margin-right: 24px;
}

.ladiui-check-item-image img {
    width: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}

.checkout-button {
    padding: 15px 45px !important;
    font-size: 20px !important;
    font-weight: 300 !important;
    margin-top:30px;
}

.ladiui-checkout-item-info {
    float: left;
}

.ladiui-checkout-item-info h3 {
    color: #44566c;
}

.ladiui-checkout-item-info p {
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
}

.ladiui-checkout-item-total {
    float: right;
    position: relative;
}

.ladiui-checkout-item {
    clear: both;
    border-bottom: 1px solid #ebedf2;
    width: 100%;
    overflow: hidden;
    padding: 10px 0px;
}

.ladiui-checkout-item:last-child {
    border-bottom: 0px;
}



.ladiui-checkout-left-content {
    float: right;
}

.ladiui-checkout-right-content,
.ladiui-checkout-left-content {
    padding: 20px 50px;
    width: 85%;
}

.ladiui-checkout-coupon-button {
    min-width: 120px;
    border-radius: 0px 4px 4px 0px !important;
}

.ladiui-checkout-coupons {
    margin-top: 30px;
}

.ladiui-checkout-coupons input {
    border-radius: 4px 0px 0px 4px !important;
    height: 50px !important;
    font-size: 16px !important;
}

.ladiui-checkout-form-total {
    margin-top: 26px;
}

.ladiui-checkout sup {
    font-size: 10px;
    position: relative;
    top: -6px;
}

.ladiui-checkout-total-row {
    line-height: 36px;
}

.ladiui-checkout-total-row span {
    display: inline-block;
    width: 50%;
    text-align: left;
}

.ladiui-checkout-total-row span:last-child {
    font-weight: 700;
    text-align: right;
}


.ladiui-checkout .ladiui-search-dropdown>.text {
    line-height: 50px;
}

.ladiui-checkout .ladiui-search-dropdown {
    height: 50px;
}

.ladiui-checkout .ladiui-search-dropdown::after {
    line-height: 50px !important;
}

::placeholder {
    color: #666;
}

.ladiui-checkout-step ul li {
    position: relative;
    padding: 8px 30px;
}

.ladiui-checkout-step ul li:first-child {
    padding-left: 0px;
}

.ladiui-checkout-step ul li:first-child::before {
    content: "";
    position: absolute;
    width: 66px;
    height: 3px;
    background: #efefef;
    top: 19px;
    right: -36px;
}

.ladiui-checkout-step ul li:first-child::after {
    content: "";
    position: absolute;
    right: -6px;
    top: 15px;
    font-family: "Ladi Icons";
    font-size: 5px;
    text-align: center;
    line-height: 16px;
    width: 10px;
    height: 10px;
    background: #1a73e8;
    border-radius: 50%;
    color: #fff;
}

.ladiui-checkout .ladiui-tabs li a {
    font-size: 16px;
    border: 1px solid #efefef;
    border-radius: 16px;
    padding: 6px 20px;
}

.ladiui-checkout .ladiui-tabs li.active a {
    border-color: #1a73e8;
    position: relative;
}

.ladiui-checkout .ladiui-tabs li.active:last-child a::before {
    content: "";
    height: 2px;
    background: #1a73e8;
    position: absolute;
    width: 30px;
    top: 16px;
    left: -31px;
}

.ladiui-checkout .ladiui-tabs li.active a::before {
    content: "";
    height: 2px;
    background: #1a73e8;
    position: absolute;
    width: 30px;
    top: 16px;
    right: -31px;
}

.ladiui-checkout .ladiui-search-dropdown .ladiui-search-dropdown-menu {
    bottom: 60px;
    top: inherit;
}

.ladiui-checkout-total-row.last {
    border-top: 1px solid #efefef;
}

.ladiui-checkout-right-content {
    float: left;
    width: 80%;
}

.ladiui-checkout-payment-group {
    background: #fff;
    background-clip: padding-box;
    border: 1px #d9d9d9 solid;
    border-radius: 5px;
    color: #545454;
}

.ladiui-checkout-payment-body {
    padding: 20px;
    background: #fafafa;
    display: none;
}

.ladiui-checkout-payment-header {
    padding: 15px 20px;
    border-top: 1px solid #d9d9d9;
}

.ladiui-checkout-payment-body.active{
    border-top: 1px solid #d9d9d9;
}

.ladiui-checkout-payment-header label {
    color: #333 !important;
    font-weight: 500;
}

.payment-box-content {
    padding: 25px 155px;
    font-weight: 300;
}

.ladiui-checkout-list-cards {
    float: right;
}

.ladiui-checkout-list-cards li {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
}

.ladiui-checkout-payment:first-child .ladiui-checkout-payment-header {
    border-top: 0px;
}

.ladiui-checkout-payment-body.active{
    display: block;
}