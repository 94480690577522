/*--------------------------------

LadiIcons Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'LadiIcons';
  src: url('../fonts/LadiIcons.eot');
  src: url('../fonts/LadiIcons.eot') format('embedded-opentype'), url('../fonts/LadiIcons.woff2') format('woff2'), url('../fonts/LadiIcons.woff') format('woff'), url('../fonts/LadiIcons.ttf') format('truetype'), url('../fonts/LadiIcons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.ladi-icon {
  display: inline-block;
  font: normal normal normal 1em/1 'LadiIcons';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.ladi-icon-sm {
  font-size: 0.8em;
}
.ladi-icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.ladi-icon-16 {
  font-size: 16px;
}
.ladi-icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.ladi-icon-bg-square,
.ladi-icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.ladi-icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.ladi-icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.ladi-icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.ladi-icon-ul > li > .ladi-icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.ladi-icon-is-spinning {
  -webkit-animation: ladi-icon-spin 2s infinite linear;
  -moz-animation: ladi-icon-spin 2s infinite linear;
  animation: ladi-icon-spin 2s infinite linear;
}
@-webkit-keyframes ladi-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes ladi-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes ladi-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.ladi-icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ladi-icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ladi-icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ladi-icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.ladi-icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.icon-home::before {
  content: "\ea02";
}

.icon-dots::before {
  content: "\ea03";
}

.icon-c-add::before {
  content: "\ea04";
}

.icon-img::before {
  content: "\ea05";
}

.icon-button::before {
  content: "\ea06";
}

.icon-text::before {
  content: "\ea07";
}

.icon-paragraph::before {
  content: "\ea08";
}

.icon-list-bullet::before {
  content: "\ea09";
}

.icon-shape-line::before {
  content: "\ea0a";
}

.icon-shape-rectangle::before {
  content: "\ea0b";
}

.icon-shape-star::before {
  content: "\ea0c";
}

.icon-html::before {
  content: "\ea0d";
}

.icon-video-player::before {
  content: "\ea0e";
}

.icon-slider::before {
  content: "\ea0f";
}

.icon-gallery::before {
  content: "\ea10";
}

.icon-form::before {
  content: "\ea11";
}

.icon-time-clock::before {
  content: "\ea12";
}

.icon-section::before {
  content: "\ea13";
}

.icon-popup::before {
  content: "\ea14";
}

.icon-app-store::before {
  content: "\ea15";
}

.icon-triangle-down::before {
  content: "\ea16";
}

.icon-triangle-up::before {
  content: "\ea17";
}

.icon-publish::before {
  content: "\ea18";
}

.icon-cloud-download::before {
  content: "\ea19";
}

.icon-preview::before {
  content: "\ea1a";
}

.icon-b-preview::before {
  content: "\ea1b";
}

.icon-undo::before {
  content: "\ea1c";
}

.icon-redo::before {
  content: "\ea1d";
}

.icon-settings::before {
  content: "\ea1e";
}

.icon-history::before {
  content: "\ea1f";
}

.icon-fonts::before {
  content: "\ea21";
}

.icon-mobile::before {
  content: "\ea22";
}

.icon-pc::before {
  content: "\ea23";
}

.icon-align-justify::before {
  content: "\ea24";
}

.icon-back-arrow::before {
  content: "\ea25";
}

.icon-e-remove::before {
  content: "\ea26";
}

.icon-data-upload::before {
  content: "\ea27";
}

.icon-dots-to-close::before {
  content: "\ea28";
}

.icon-floppy-disk::before {
  content: "\ea29";
}

.icon-cross-vertical::before {
  content: "\ea2a";
}

.icon-hyperlink::before {
  content: "\ea2b";
}

.icon-link-broken::before {
  content: "\ea2c";
}

.icon-text-color::before {
  content: "\ea2d";
}

.icon-bold::before {
  content: "\ea2e";
}

.icon-italic::before {
  content: "\ea2f";
}

.icon-underline::before {
  content: "\ea30";
}

.icon-strikethrough::before {
  content: "\ea31";
}

.icon-crop::before {
  content: "\ea32";
}

.icon-duplicate::before {
  content: "\ea33";
}

.icon-bin::before {
  content: "\ea34";
}

.icon-lock::before {
  content: "\ea35";
}

.icon-unlocked::before {
  content: "\ea36";
}

.icon-arrow-up::before {
  content: "\ea37";
}

.icon-arrow-down::before {
  content: "\ea38";
}

.icon-phone::before {
  content: "\ea39";
}

.icon-email::before {
  content: "\ea3a";
}

.icon-notify::before {
  content: "\ea3b";
}

.icon-themes::before {
  content: "\ea3c";
}

.icon-tag::before {
  content: "\ea3d";
}

.icon-domain::before {
  content: "\ea3e";
}

.icon-menu::before {
  content: "\ea3f";
}

.icon-apps::before {
  content: "\ea40";
}

.icon-zoom::before {
  content: "\ea41";
}

.icon-double-arrow-right::before {
  content: "\ea42";
}

.icon-double-arrow-left::before {
  content: "\ea43";
}

.icon-left-arrow::before {
  content: "\ea44";
}

.icon-right-arrow::before {
  content: "\ea45";
}

.icon-down-arrow::before {
  content: "\ea46";
}

.icon-up-arrow::before {
  content: "\ea47";
}

.icon-page-setting::before {
  content: "\ea48";
}

.icon-status::before {
  content: "\ea49";
}

.icon-bill::before {
  content: "\ea4a";
}

.icon-orders::before {
  content: "\ea4b";
}

.icon-products::before {
  content: "\ea4c";
}

.icon-customers::before {
  content: "\ea4d";
}

.icon-users::before {
  content: "\ea4e";
}

.icon-discounts::before {
  content: "\ea4f";
}

.icon-store::before {
  content: "\ea50";
}

.icon-ring::before {
  content: "\ea51";
}

.icon-maps::before {
  content: "\ea52";
}

.icon-edit::before {
  content: "\ea53";
}

.icon-intergration::before {
  content: "\ea54";
}

.icon-c-warning::before {
  content: "\ea55";
}

.icon-c-question::before {
  content: "\ea56";
}

.icon-crown::before {
  content: "\ea57";
}

.icon-calendar::before {
  content: "\ea58";
}

.icon-print::before {
  content: "\ea59";
}

.icon-hot-key::before {
  content: "\ea5a";
}

.icon-keyboard::before {
  content: "\ea5b";
}

.icon-blog::before {
  content: "\ea5c";
}

.icon-logo-facebook::before {
  content: "\ea5d";
}

.icon-b-chat::before {
  content: "\ea5e";
}

.icon-logout::before {
  content: "\ea5f";
}

.icon-key::before {
  content: "\ea60";
}

.icon-access-key::before {
  content: "\ea61";
}

.icon-project::before {
  content: "\ea62";
}

.icon-affilate::before {
  content: "\ea63";
}

.icon-referral::before {
  content: "\ea64";
}

.icon-align-left-2::before {
  content: "\ea65";
}

.icon-align-center::before {
  content: "\ea66";
}

.icon-align-right-2::before {
  content: "\ea67";
}

.icon-dock-bottom::before {
  content: "\ea68";
}

.icon-dock-left::before {
  content: "\ea69";
}

.icon-dock-top::before {
  content: "\ea6a";
}

.icon-dock-right::before {
  content: "\ea6b";
}

.icon-format-left::before {
  content: "\ea6c";
}

.icon-format-right::before {
  content: "\ea6d";
}

.icon-align-left::before {
  content: "\ea6e";
}

.icon-distribute-horizontal::before {
  content: "\ea6f";
}

.icon-distribute-vertical::before {
  content: "\ea70";
}

.icon-align-right::before {
  content: "\ea71";
}

.icon-align-top::before {
  content: "\ea72";
}

.icon-align-bottom::before {
  content: "\ea73";
}

.icon-align-center-horizontal::before {
  content: "\ea74";
}

.icon-align-center-vertical::before {
  content: "\ea75";
}

.icon-caps-all::before {
  content: "\ea76";
}

.icon-check-single::before {
  content: "\ea77";
}

.icon-d-check::before {
  content: "\ea78";
}

.icon-g-check::before {
  content: "\ea79";
}

.icon-delivery::before {
  content: "\ea7a";
}

.icon-palette::before {
  content: "\ea7b";
}

.icon-border-radius::before {
  content: "\ea7c";
}

.icon-sync::before {
  content: "\ea7d";
}

.icon-copy::before {
  content: "\ea7e";
}

.icon-flip-horizontal::before {
  content: "\ea7f";
}

.icon-flip-vertical::before {
  content: "\ea80";
}

.icon-triangle-right::before {
  content: "\ea81";
}

.icon-triangle-left::before {
  content: "\ea82";
}

.icon-refresh::before {
  content: "\ea83";
}

.icon-chat-support::before {
  content: "\ea84";
}

