.ladiui.form-control {
    height: 36px;
}

.ladiui.btn-primary {
    font-weight: 500;
}

.ladi-date-picker-icon {
    float: right;
    margin-right: 10px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
}

.ladi-edit-icon {
    color: var(--main-primary);
    cursor: pointer;
}

.ladi-payment-info {
    height: 120px !important;
    width: 440px;
    resize: none;
}

.ladiui-table-actions {
    z-index: 6;
    margin-bottom: 12px;
}

.page-profile .profile-box {
    display: block;
    padding-bottom: 30px;
    min-height: 460px;
    position: relative;
    width: 600px;
    margin: 0px auto;
}

.profile-avatar {
    width: 110px;
    margin: 30px auto;
    height: 110px;
    position: relative;
    border-radius: 50%;
    background-color: rgba(169, 194, 209, 0.1);
}

.profile-avatar img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
}

.profile-avatar img.icon-change-avatar {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 6px;
    top: 4px;
}

.profile-avatar input {
    display: none;
}

.profile-avatar .ladiui-button-upload {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: #fafafa;
    border-radius: 50%;
}

.change-password {
    display: block;
    margin: 20px 0px;
}

.change-password a {
    color: #1a73e8;
}

.page-profile ul {
    margin: 0px;
    padding: 0px;
}

.page-profile ul li {
    list-style: none;
    line-height: 24px;
}

.list-packages {
    padding: 15px;
}

.list-packages .package-item {
    background: #fafafa;
    padding: 10px;
    margin-bottom: 10px;
}

.welcome h3 {
    font-weight: 500;
    font-size: 25px;
}

.profile-info {
    padding: 20px;
    text-align: center;
}

.profile-info h3 {
    font-weight: 500;
}

.profile-info ul li {
    line-height: 35px;
}

.page-ladiuid-account .card-body {
    padding: 20px;
}

.referral-link .ladiui-input-group-prepend {
    margin-left: -5px;
}


.referral-link .ladiui-input-group>.ladiui-input-group-prepend>.ladiui-input-group-text {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

}

.referral-link input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: #fafafa !important;
    font-style: italic;
}

.referral-link .ladiui-input-group-text {
    border-right: 1px solid #ebedf2 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    height: 40px;
    cursor: pointer;
    font-weight: 300 !important;
    background: var(--main-primary);
    color: #fff;
    border: 0px;
}

.referral-title {
    margin-bottom: 10px;
}


.page-ladiuid-account .profile-avatar.no-avatar {
    line-height: 110px;
    background: var(--main-primary);
    color: #fff;
}

.ladipage-account-avatar .ladiui.avatar {
    width: 68px;
    height: 68px;
    margin-top: 3px;
    background: var(--main-primary);
}

.builder-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 5%;
    overflow: hidden;
    background-color: #fafafa;
    z-index: 900000080;
}

.ladiui-topbar-noavatar {
    background: var(--main-primary);
    color: white;
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 75px;
}

.ladiui.submenu>li>a.active {

    color: #0554d9;
}

.overview-content {
    background-color: #f6f7f9;
    border: 1px solid #f6eee0;
    border-radius: 20px;
    margin: 20px 0;
    font-style: italic;
    padding: 0 15px 15px;
    font-size: 15px;
}

.ladiui.button.disable {
    color: #fff;
    background-color: rgb(113, 117, 124);
    border-color: rgb(113, 117, 124)
}

.grecaptcha-badge {
    visibility: collapse !important;
}

[data-tooltip]:before {
    content: attr(data-tooltip);
}

[data-tooltip] {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

[data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: #fff;
    padding: 12px;
    color: #444;
    font-size: 14px;
    line-height: initial;
    min-width: 145px;
    text-align: center;
    border-radius: 4px;
    border-color: #f8fafb;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, .2), 0 0 0 1px rgba(0, 0, 0, .05);
}

[data-tooltip] i {
    font-size: 12px;
    position: relative;
}

.tooltip-mini[data-tooltip]:before {
    padding: 5px;
    min-width: 100px;
}

[data-tooltip-position="top"]:before,
[data-tooltip-position="bottom"]:before {
    left: 50%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

/* Dynamic vertical centering */
[data-tooltip-position="right"]:before,
[data-tooltip-position="left"]:before {
    top: 50%;
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

[data-tooltip-position="top"]:before {
    bottom: 100%;
    margin-bottom: 6px;
}

[data-tooltip-position="right"]:before {
    left: 100%;
    margin-left: 6px;
}

[data-tooltip-position="bottom"]:before {
    top: 100%;
    margin-top: 10px;
}

[data-tooltip-position="left"]:before {
    right: 100%;
    margin-right: 6px;
}

/* Tooltip arrow styling/placement */
[data-tooltip]:after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #f8fafb;
    border-right-color: transparent;
    border-left-color: transparent;
    opacity: 0.95;
    border: 1px solid #fafafa;
}

/* Dynamic horizontal centering for the tooltip */
[data-tooltip-position="top"]:after,
[data-tooltip-position="bottom"]:after {
    left: 50%;
    margin-left: -6px;
}

/* Dynamic vertical centering for the tooltip */
[data-tooltip-position="right"]:after,
[data-tooltip-position="left"]:after {
    top: 50%;
    margin-top: -6px;
}

[data-tooltip-position="top"]:after {
    bottom: 100%;
    border-width: 6px 6px 0;
    border-color: #fff;
    border-left-color: rgba(169, 194, 209, .1);
    border-right-color: rgba(169, 194, 209, .1);
}

[data-tooltip-position="right"]:after {
    left: 100%;
    border-width: 6px 6px 6px 0;
    border-color: #fff;
    border-top-color: rgba(169, 194, 209, .1);
    border-bottom-color: rgba(169, 194, 209, .1);
}

[data-tooltip-position="bottom"]:after {
    top: 114%;
    border-width: 0 6px 6px;
    border-color: #fff transparent;
}

[data-tooltip-position="left"]:after {
    right: 100%;
    border-width: 6px 0 6px 6px;
    border-color: #fff;
    border-top-color: rgba(169, 194, 209, .1);
    border-bottom-color: rgba(169, 194, 209, .1);
}

/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 1000;
}

* {
    box-sizing: border-box;
    margin: 0 auto;
}

body {
    font-family: 'Roboto', sans-serif;
}

.ladiui.wrapper {
    width: 100%;
    min-width: 1366px;
    height: 0;
}

.ladiui.topbar {
    height: 64px;
    border-bottom: 1px solid var(--main-line);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-white);
    position: fixed;
    width: 100%;
    z-index: 10;
}

.ladiui.main {
    position: relative;
    margin-left: 74px;
    width: calc(100% - 74px);
}

.ladiui.topbar-menu {
    position: fixed;
    padding: 19px 0px 19px 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--main-secondary);
    cursor: pointer;
}

.ladiui.topbar-menu-right {
    position: fixed;
    right: 0;
}

.ladiui.btn-group {
    padding: 14px 0px 14px 0px;
}

.ladiui.app-tools img {
    pointer-events: none;
}

.ladiui.tool-notificiations {
    cursor: pointer;
    padding: 19px 12px 19px 24px;
}

.ladiui.noti.dropdown-menu {
    right: 125px;
    width: 400px;
}

.ladiui.noti-content {
    display: flex;
    padding: 16px;

}

.ladiui.noti-content.active {
    background-color: rgba(24, 82, 250, .05) !important;
}

.ladiui.noti-content:hover {
    background-color: var(--main-line);
}

.ladiui.noti-header {
    padding: 6px 16px 18px;
    display: flex;
    justify-content: space-between;
}

.ladiui.noti-header-right {
    color: var(--main-primary);
}

.ladiui.noti-header-right i {
    margin-right: 4px;
}

.ladiui.noti-i {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    pointer-events: none;

}

.ladiui.noti-i img {
    width: 40px;
    height: 40px;
    border-radius: 50%;

}


.ladiui.noti-text {
    margin-left: 56px;
    cursor: pointer;
    pointer-events: none;
}

.ladiui.noti-sub {
    margin-top: 4px;
    color: var(--main-secondary);
}

.ladiui.noti-time {
    margin-top: 8px;
    color: var(--main-secondary);
}

.ladiui.tool-help {
    padding: 20px 12px 20px 12px;
}

.ladiui.tool-help .dropdown-menu {
    right: 75px;
}

.ladiui.tool-help .dropdown-item {
    display: flex;
    align-items: center;
}

.ladiui.tool-language {
    padding: 20px 12px 20px 12px;
}

.ladiui.language-img {
    color: rgba(86, 101, 119, 1);
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    pointer-events: none;
}

.ladiui.language-img span {
    display: block;
    line-height: 24px;
    margin-left: 8px;
}

.ladiui.tool-language img {
    border-radius: 20px;
    width: 22px;
}

.ladiui.tool-language .dropdown-menu {
    min-width: 150px;
    right: 0px;
}

.ladiui.tool-language .dropdown-menu img {
    margin-right: 8px;
}

.ladiui.my-account {
    cursor: pointer;
    width: 70px !important;
    min-width: initial;
}

.ladiui.dropdown-menu.account {
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 5%) !important;
}

.ladiui.my-account.dropdown-toggle::after {
    content: none;
}

.ladiui.dropdown-toggle.nav-bar {
    min-width: 200px;
}

.ladiui.my-account-avata {
    padding: 12px 0px 12px 12px;
    pointer-events: none;
}

.ladiui.my-account-avata img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.ladiui.my-account-info {
    padding-left: 16px;
    pointer-events: none;
}

.ladiui.my-account-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    pointer-events: none;
}

.ladiui.my-account-type {
    padding: 2px 0px 0px 0.5px;
}

.ladiui.account.dropdown-menu {
    width: 332px;
    right: 10px;
}

.ladiui.account .dropdown-item {
    padding: 12px 21px;
}

.ladiui.account-header {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.account-header-text {
    pointer-events: none;
}

.ladiui.account-content {
    padding: 14px 24px 0;
}

.ladiui.account-content-footer {
    padding: 0px 24px;
    margin-bottom: 12px;
}

.ladiui.account-footer {
    border-top: 1px solid var(--main-line);
}

.ladiui.account-avatar {
    margin-right: 16px;
    display: flex;
    position: relative;
}

.ladiui.account-avatar a {
    padding: .5px;
    border-radius: 20px;
    background-color: var(--main-white);
    position: absolute;
    top: 42px;
    left: 42px;
}

.ladiui.account-body-content {
    pointer-events: none;
}

.ladiui.account-avatar img {
    pointer-events: none;
    border-radius: 50%;
}

.ladiui.avt {
    width: 64px;
    height: 64px;
}

.ladiui.avartar {
    pointer-events: none;
}

.ladiui.account-body-item {
    display: flex;
    align-items: center;
    padding: 12px 24px;
}

.ladiui.my-account-type i {
    margin-right: 0;
}

.ladiui.dropdown-item img {
    margin: 0px 8px 0px 0px;
    align-items: center;
    vertical-align: middle;
}

.ladiui.dropdown-item.account-body-item i {
    margin-right: 0;
}

.ladiui.account-tick {
    background-color: inherit;
    position: absolute;
    right: 24px;
}

.ladiui.account-body-item:first-child {
    margin-top: 12px;
}

.ladiui.account-body-item:last-child {
    margin-bottom: 12px;
}

.ladiui.account-body-item:hover {
    background-color: var(--main-line);
}

.ladiui.body-avatar {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
    pointer-events: none;
}

.ladiui.body-avatar img {
    width: 36px;
    height: 36px;

}

.ladiui.account-status {
    display: flex;
    justify-items: center;
    margin-top: 4px;
}

.ladiui.account-body-item.active .body-avatar {
    border: 2px solid var(--main-primary);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-tick {
    background-color: var(--main-primary);
}

.ladiui.account-body-item.active .body-avatar img {
    border: 2px solid var(--main-white);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-name {
    color: var(--main-primary);
}

.ladiui.account-duration {
    margin-left: 8px;
}

.ladiui.icon-drd {
    padding: 0px 20px 0px 8px;
}

.ladiui.content-navigation .dropdown {
    display: flex;
    flex-direction: column-reverse;
}

.ladiui.new-page.dropdown-menu {
    width: 400px;
    padding: 0;
    margin-bottom: 44px;
}

.ladiui.new-page.dropdown-item {
    display: flex;
    align-items: center;
    padding: 16px;
}

.ladiui.content-sub {
    min-width: 530px;
}

.ladiui.new-page-content {
    margin-left: 8px;
}

/*Hover*/
.ladiui.nav {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 74px;
    height: 100vh;
    background-color: var(--main-line);
    transition: width .3s;
}

.ladiui.logo-item.logo {
    padding: 16px 21px 16px 21px;
    position: relative;
    opacity: 1;
}

.ladiui.logo-item.logo-hover {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}


.ladiui.nav:hover .logo-item.logo-hover {
    position: relative;
    opacity: 1;
    transition: opacity .5s;

}

.ladiui.nav:hover .logo-item.logo {
    position: absolute;
}

.ladiui.nav:hover {
    display: block;
    width: 215px;
}

.ladiui.menu-text {
    position: absolute;
    pointer-events: none;
    left: 50px;
    opacity: 0;
    width: 0;
    overflow: hidden;
    transition: opacity .2s, width .3s;
}

@keyframes ladiLogo {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.ladiui.nav:hover .menu-text {
    opacity: 1;
    width: 100%;
}

.ladiui.nav:hover .menu-item {
    width: 215px;
}

.ladiui.menu-item {
    padding: 16px 0px 16px 23px;
    width: 74px;
    transition: width .3s;
    border-left: 2px solid var(--secondary-color);
}

.ladiui.menu-item a i {
    background-color: var(--main-secondary);
}

.ladiui.menu-item .icon-prev {
    margin-left: 16px;
}

.ladiui.menu-item:hover .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item:hover i {
    background-color: var(--main-primary);
}


.ladiui.logo-item.logo-hover {
    padding: 16px 21px;
}

.ladiui.menu-item.active {
    background-color: var(--main-white);
    border-left: 2px solid var(--main-primary);
}

.ladiui.menu-item.active i {
    background-color: var(--main-primary);
}

.ladiui.menu-item .icon-prev {
    transform: rotate(90deg);
}

.ladiui.menu-item.active .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item .dropdown-toggle {
    min-width: 50px;
    width: 50px;
}

.ladiui.menu-item .dropdown-toggle:focus {
    border: none;
}

.ladiui.menu-item .dropdown-toggle::after {
    content: none;
}

.ladiui.menu-item .dropdown-menu {
    padding: 0;
    box-shadow: none;
    background-color: inherit;
}

.ladiui.nav:hover .dropdown-toggle {
    min-width: 200px;
    width: 200px;
    transition: width .3s;
}

.ladiui.nav:hover .ladiui.menu-child {
    position: relative;
    opacity: 1;
    width: 100%;
}

.ladiui.menu-child {
    position: absolute;
    opacity: 0;
    width: 0;
    transition: opacity .2s;
}

.ladiui.menu-child-item {
    padding: 12px 0 12px 34px;
}

.ladiui.menu-child-item:first-child {
    padding: 24px 0 12px 34px;
}

.ladiui.menu-child li a:hover {
    color: var(--main-primary);
}

.ladiui.menu-child li a.active {
    color: var(--main-primary);
}


.ladiui.menu-child li a {
    color: var(--main-secondary);
}

.ladiui.menu-text {
    white-space: nowrap;
    padding-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}


.ladiui.head-noti {
    margin-bottom: 4px;
}

.ladiui.tag-form {
    display: inline-table;
}

.ladiui.dropdown-menu.custom-header {
    min-width: 200px;
}

.facet-container {
    width: 530px;
}

.right {
    float: right;
}

.left {
    float: left;
}

.facet-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    background: #eee;
    padding: 5px;
    width: 143px;
    min-height: 1.5em;
    font-size: 0.85em;
}

.facet-list li {
    margin: 5px;
    padding: 5px;
    font-size: 1.2em;
    width: 120px;
}

.facet-list li.placeholder {
    height: 1.2em
}

.facet {
    border: 1px solid #bbb;
    background-color: #fafafa;
    cursor: move;
}

.facet.ui-sortable-helper {
    opacity: 0.5;
}

.placeholder {
    border: 1px solid orange;
    background-color: #fffffd;
}

/* width */
/* ::-webkit-scrollbar {
    width: 4px;
} */

/* Track */
::-webkit-scrollbar-track {
    background: var(--main-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--main-mute);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--main-secondary);
}

/*------------------------------------------------------------Ladipage.css---------------------------------------------------------*/
/* ----------------------------------------modal-------------------------------------------------------- */
.ladiui.modal .search-field {
    height: 32px;
}

.ladiui.modal .search-group i {
    top: 3px;
}

/*-----------------------------------------Ladipage Home---------------------------------------*/
.ladiui.content {
    height: 90vh;
    position: relative;
    top: 64px;
    justify-content: space-between;
    align-items: center;
}

.ladiui.content-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.content-sub {
    max-width: 530px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.sub-weight {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
    margin: 0px 4px;
}

.ladiui.sub-weight.custom-one {
    margin: 0px 4px 0px 0px;
}

.ladiui.content-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
}

.ladiui.content-navigation .dropdown-toggle::after {
    content: none;
}

.ladiui.btn-primary {
    margin: 0px 0px 0px 0px;
}

.ladiui.end {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px 24px 0px 24px;
}

.ladiui.tutorial a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.tutorial {
    border-bottom: 1px solid var(--main-primary);
}

/*---------------------------------------------------------------------------------------Landingpage list-------------------------------------------------------------------------------*/
.ladiui.content-main {
    position: absolute;
    top: 0px;
    width: calc(100% - 236px);
    min-width: 1012px;
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 24px 40px 24px;
}

.ladiui.content-main1 {
    position: absolute;
    top: 64px;
    width: 100%;
    min-width: 1012px;
    /* height: 82vh; */
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 20px 40px 24px;
}

.ladiui.content-main-full {
    padding: 88px 20px 40px 24px;
}

.ladiui.main-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

.ladiui.content-list-landingpage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
}

.ladiui.content-main-title {
    justify-content: space-between;
    margin-bottom: 28px;
}

.ladiui.content-tag {
    max-width: 236px;
    width: calc(100% - 236px);
    height: 100%;
    padding: 24px 24px 0px 23px;
    position: fixed;
    top: 64px;
    left: 74px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
    border-right: 1px solid var(--main-line);
}

.ladiui.content-tag-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.content-tag-setting {
    position: absolute;
    right: 24px;
}


.ladiui.btn-primary {
    flex-direction: row;
    align-items: center;
}

.ladiui.btn-custom-img {
    padding: 9px 8px 9px 2px;
    pointer-events: none;
}

.ladiui.btn-custom-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
    pointer-events: none;
}

.ladiui.table {
    margin-top: 25px;
    min-width: 1000px;
    width: 100%;
}

.ladiui.table.fix-header thead tr {
    background-color: var(--main-white);
    position: sticky;
    top: 0px;
    z-index: 10;
}

.ladiui.table.fix-header thead tr th {
    vertical-align: middle;
    color: var(--main-default);
    padding: 0 18px 20px 0px;
}

.ladiui.table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    overflow-x: hidden;
    max-height: calc(80vh - 157px);
}


.ladiui.btn-group.custom-btn-list-landingpage {
    margin-left: 24px;
}

.ladiui.content-main-tool {
    margin-top: 24px;
    margin-bottom: 24px;
}

.ladiui.custom-btn-list-landingpage {
    min-width: 154px;
}

.ladiui-btn-tag.tags-list {
    margin: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 77px;
}

.ladiui-btn-tag.custom {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    margin: 8px 8px 0px 0px;
}

.tooltip {
    display: inline;
    /* margin-right: 8px; */
}

.ladiui-tag-list .tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
}

.ladiui.dropdown-item {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    /* max-width: 200px; */
}

.ladiui.custom-text-tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ladiui-btn-tag.tags-list:hover {
    background-color: var(--main-primary);
    color: var(--main-white);
    cursor: pointer;
}

.ladiui-btn-tag.tags-list:active {
    background-color: var(--main-primary);
    color: var(--main-white);
    cursor: pointer;
}

.ladiui-btn-tag.tags-list:active {
    background-color: var(--main-primary);
    color: var(--main-white);
}

.ladiui.content-main-tool {
    height: 36px;
}


/*--------------------------------------------------------------------------------Ladipage banner-ads-list------------------------------------------------------------------------------*/
.ladiui.dropdown-toggle.custom-pre-page {
    min-width: 64px;
}

.ladiui.dropdown-text.custom-pre-page {
    margin: 0;
    padding: 0px 16px 0px 0px;
}

.ladiui.dropdown-toggle.custom-pre-page:after {
    margin: 12px 12px 12px 0px;
}

.ladiui.dropdown-menu.custom-pre-page {
    min-width: 64px;
    bottom: 22px;
}

.ladiui.btn-group.custom-pre-page {
    padding: 0;
}

.ladiui.btn.custom-pre-page {
    padding: 6px 12px;
}

.ladiui.from-filter {
    min-height: 32px;
}

.ladiui.showing {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 20px;
}

.ladiui.showing span {
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    color: var(--main-default);
}

.ladiui.search-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.ladiui.col-one.custom-th-banner {
    min-width: 344px;
}

.ladiui.setting-content {
    width: 236px;
    padding: 24px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.ladiui.content-tag-text {
    color: var(--main-default);
    cursor: pointer;
}

.ladiui.ladiui.content-tag-text.active {
    color: var(--main-primary);
}

.ladiui.setting-name {
    margin-bottom: 32px;
}

.ladiui.setting-item {
    margin: 24px 0px 32px 0px;
}

.ladiui.switch-content.form-vertical {
    margin-bottom: 10px;
}

.ladiui.form-vertical {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.ladiui.form-vertical:last-child {
    margin-bottom: 24px;
}

.ladiui.input-name {
    width: 100px;
    margin-right: 24px;
}

.ladiui.setting-input-name {
    width: 150px;
    margin-right: 24px;
}

.ladiui.col-tow.custom-th-banner {
    min-width: 344px;
}

.ladiui.col-three.custom-th-banner {
    min-width: 269px;
}

/* -------------------------------------------------Setting---------------------------------------- */
.ladiui.setting.content-main table {
    margin-top: 18px;
}

.ladiui.setting-content a:hover {
    color: var(pr);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.content-parent {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content-left {
    position: fixed;
    top: 65px;
    border-right: 1px solid var(--main-line);
    height: 100%;
    z-index: 999;
    background-color: var(--main-white)
}

.ladiui.shared.setting-content-right {
    width: 636px;
    /* padding-bottom: 18px; */
}

.ladiui.setting-content-right:last-child {
    border-bottom: none;
}

.ladiui.setting-content-right .btn-group {
    margin: 0 20px 0 0;
}

.ladiui.setting-content-right .btn-group:first-child {
    margin-left: 0px;
}

.ladiui.content-parent .table {
    margin-top: 0;
}

.ladiui.content-parent:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.ladiui.switch-btn.form-vertical {
    justify-content: end;
    margin-top: 34px;
}

.ladiui.switch-btn.form-vertical button {
    margin-right: 24px;
}

.ladiui.switch-btn.form-vertical button:last-child {
    margin-right: 0;
}

.ladiui.col-six.custom-th-banner {
    min-width: 18px;
}

.ladiui.icon.icon-ldp-list {
    margin-right: 16px;
}



/*--------------------------------------------------------------------------------banner grid----------------------------------------------------------------------------------------*/

.ladiui.card-custom {
    width: 310px;
    height: 250px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    border-radius: 2px;
}

.ladiui.card-img-top-custom {
    width: 310px;
    height: 250px;
    position: absolute;
}


.ladiui.card-body-custom {
    background-color: var(--main-white);
    position: relative;
    height: 60px;
    top: 190px;
}

.ladiui.card-title-custom {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin: 8px 0px 4px 17.47px;
}

.ladiui.text-time {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
    margin-left: 1px;
}

.ladiui.card-custom-date {
    margin: 0px 0px 0px 17.47px;
}

.ladiui.banner-ads-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
    row-gap: 32px;
    margin-top: 0px;
}

.ladiui.content-main-tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.ladiui.content-main-tool-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
}

.ladiui.table-show {
    display: none;
}

.ladiui.grid-show {
    display: none;
}

.ladiui.icon-list-view.tab-link,
.ladiui.icon-grid-view.tab-link {
    border-bottom: none;
}

/* ---------------------------------------------------------------------------------------intro---------------------------------------------------------------------------------- */
.ladiui.intro-content {
    padding-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ladiui.intro-head {
    margin-bottom: 19px;
}

.ladiui.intro-header {
    text-align: center;
    margin-bottom: 40px;
}

.ladiui.intro-body {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
}

.ladiui.intro-body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 243px;
    text-align: center;
    margin: 0 121px;
}

.ladiui.intro-body-content i {
    margin-bottom: 53px;
}

/* .ladiui.intro-body-content span {
    margin-bottom: 8px;
} */

.ladiui.intro-footer {
    width: 528px;
    margin-bottom: 24px;
    text-align: center;
}

/*----------------------------------------------------------------------------------------custom table dynamic--------------------------------------------------------------------------------------------------------------*/
.ladiui.col-one.custom-dynamic {
    min-width: 324px;
}

.ladiui.col-tow.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-three.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-threes.custom-dynamic {
    min-width: 193px;
}

.ladiui.col-for.custom-dynamic {
    min-width: 132px;
}

.ladiui.col-fors.custom-dynamic {
    min-width: 112px;
}

.ladiui.col-five.custom-dynamic {
    min-width: 112px;
}

/*Custom form dynamic*/
.ladiui.modal-content.custom-dynamic {
    min-width: 824px;
    padding: 24px;
}

.ladiui.modal-header.dynamic {
    padding: 0px 0px 4px 0px;
}

.ladiui.item.dynamic {
    margin-top: 32px;
}

.ladiui.btn-group-dynamic {
    padding: 0px;
}

.ladiui.btn-group.dynamic {
    padding: 0px;
}

.ladiui.item-form.dynamic {
    align-items: center;
}

.ladiui.switch.sw-lg.dynamic {
    margin: 0px;
}

.ladiui.item-form {
    margin: 0px;
}

.ladiui.item-form {
    padding: 0px;
}



.ladiui.search-group .dropdown-menu {
    min-width: 270px;
}


/*----------------------------------------------------------------------------------------Custom modal home----------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-ldp-width {
    width: 600px !important;
}

.ladiui.custom-ldp {
    padding: 24px 24px 24px 24px;
}

.ladiui.modal-header.custom-ldp {
    padding: 0px 0px 16px 0px;
}

.ladiui.form-group-custom {
    margin-top: 28px;
}

.ladiui.form-check-inputs-custom {
    margin-top: 32px;

}

.ladiui.radio.custom-ldp-radio {
    margin-top: 8px;
}

.ladiui.dropdown-toggle.custom-main-tool {
    margin-left: 24px;
}

.ladiui.search-width {
    min-width: 270px !important;
}

/*----------------------------------------------------------------------------------------custom modal popup--------------------------------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-popup-width {
    width: 718px;
}

.ladiui.label-embel-code {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-text-color);
    margin-top: 20px;
}

.ladiui.input-embel-code {
    margin-top: 16px;
}

/*----------------------------------------------------------------------------------------custom dropdown people--------------------------------------------------------------------------------------------------------------*/

.ladiui.dropdown-toggle.custom-people {
    min-width: 168px;
}

.ladiui.dropdown-menu.custom-people {
    position: absolute;
    /* left: 24px; */
}

.ladiui.dropdown-menu.custom-people.field {
    position: absolute;
    left: 24px;
    min-height: 280px;
}

.ladiui.dropdown-menu.custom-people {
    padding: 0px;
}

/* --------------------------------------------------------------------------------------------Domain---------------------------------------------------------------------------------------------- */
.ladiui.domain .modal-dialog {
    width: 600px !important;
}

.ladiui.modal .form-group:first-child {
    margin-top: 0;
}

.ladiui.modal .form-group:first-child {
    margin-top: 0;
}

.ladiui.modal .btn-group {
    padding: 0;
    width: 552px;
}

.ladiui.modal .btn-group .dropdown-toggle {
    width: 552px;
}

.ladiui.modal .btn-group .dropdown-menu {
    width: 552px;
}

.ladiui.domain .span-text {
    margin-right: 40px;
}

.ladiui.domain .table-vertical td {
    padding: 0;
}


.ladiui.modal .domain-action.btn-group {
    width: 120px;
    margin-right: 16px;
}

.ladiui.modal .domain-action.btn-group .dropdown-toggle {
    min-width: 120px;
    width: 120px;
}

.ladiui.modal .domain-action.btn-group .dropdown-menu {
    min-width: 120px;
    width: 120px;
}

/* -------------------------------------------------------------------------------------------------------------Member------------------------------------------------------------------------------------------------------ */
.ladiui.member .search-field {
    width: 295px;
}

.ladiui.member .custom-btn-list-landingpage {
    width: 188px;
}

.ladiui.member .table {
    margin-top: 44px;
}

.ladiui.member .ladiui.content-main-tool {
    margin-top: 18px;
}

.ladiui.member .col-one {
    min-width: 150px;
}

.ladiui.member .col-three {
    min-width: 150px;
}

.ladiui.member table .btn-group {
    padding: 11.5px 0;
}

.ladiui .modal .btn-group.decent {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-toggle {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-menu {
    min-width: 222px;
    width: 222px;
}

.ladiui.auto-height.form-control {
    word-break: normal;
    word-wrap: break-word;
}

.ladiui.share-recource {
    display: grid;
    grid-template-columns: auto auto auto;
}

.ladiui.share-recource .span-text {
    margin: 0;
    padding: 8px 0 16px;
}

.ladiui.list-recource {
    display: none;
}

.ladiui.form-group .select-search .btn {
    max-width: none;
    text-overflow: unset;
}

.ladiui.form-group .select-search .dropdown-text {
    overflow: unset;
}

.ladiui.status.form-group .span-text {
    margin: 0 62px;
}

.ladiui.status.form-group .span-text:first-child {
    margin-left: 0;
}

.ladiui.status.form-group .span-text:last-child {
    margin-right: 0;
}

.ladiui.member .list-team .col-one {
    min-width: 220px;
}

.ladiui.member .list-team .col-five {
    min-width: 28px;
}

/*-----------------------------------------------------------------------------------------------------custom modal dropdown----------------------------------------------------------------------------------------*/
.ladiui.custom-ldp-width1 {
    min-width: 450px;
}

.ladiui.text.on-access-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.ladiui.form-check-inputs-custom1 {
    margin-top: 20px;
}

.ladiui.radio.custom-ldp-radio1 {
    margin-top: 16px;
    margin-bottom: 8px;
}

.ladiui.member .member-img-left {
    margin-right: 16px;
}

.ladiui.member .member-img-left img {
    height: 32px;
    width: 32px;
}

.ladiui.member .member-control {
    margin: 8px 0;

}

.ladiui.member .member-control:first-child {
    margin-top: 0;
}

.ladiui.member .member-control:last-child {
    margin-bottom: 0;
}

.ladiui.modal-header.custom-data-backup {
    padding: 0px 0px 4px 0px;
}

.ladiui.modal-footer.custom-ldp-decentralization {
    padding: 54px 0px 0px 0px;
}

.ladiui.help-footer {
    margin-right: 8px;
}

.ladiui.modal-footer-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-black);
    cursor: pointer;
}

.ladiui.list-user {
    margin-top: 32px;
}

.ladiui.container-list-user {
    margin: 8px 0px 16px 0px;
}

.ladiui.text-sub-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-title-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.container-list-user-left {
    display: flex;
    flex-direction: column;
}

.ladiui.container-list-user,
.ladiui.container-list-user-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/*----------------------------------------------------------------------------------------custom button popup----------------------------------------------------------------------------------------*/
.ladiui.btn-outline-primary.custom-popup {
    font-weight: 500;
    margin-right: 24px;
}

.ladiui.modal-header.custom-popupX {
    padding: 0px 0px 4px 0px;
}

.ladiui.text-sub-notification {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-sub-bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.modal-footer.custom-ldp-popupX {
    padding: 48px 0px 0px 0px;
}

/* --------------------------------------------------------------------------------------Access------------------------------------------------------------------------------ */
.ladiui.access.content-main-tool .search-group {
    width: 270px;
}

.ladiui.modal.key-access .modal-dialog {
    width: 963px;
    height: 653px;
}

.ladiui.modal.key-access .search-group {
    width: 270px;
}

.ladiui.modal.key-access .col-first {
    width: 40px;
}

.ladiui.modal.key-access .col-one {
    min-width: 120px;
    width: 120px;
}

.ladiui.modal.key-access .col-tow {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-three {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-four {
    min-width: 116px;
    width: 116px;
    text-align: center;
}

.ladiui.modal.key-access .col-five {
    min-width: 130px;
    width: 130px;
    text-align: center;
}

.ladiui.modal.key-access .col-six {
    min-width: 16px;
    width: 85px;
    text-align: center;
}

.ladiui.modal.key-access .col-seven {
    min-width: 107px;
    width: 107px;
}

.ladiui.modal.key-access .btn-group {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access .btn-group .dropdown-toggle {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access table .btn-group .dropdown-menu {
    min-width: 175px;
    width: 175px;
}

.ladiui.modal.key-access .btn-group .dropdown-menu.fixel {
    min-width: 120px;
    width: 120px;
}

.ladiui.create-key .dropdown-toggle {
    min-width: 100px;
}

.ladiui.create-key .dropdown-toggle::after {
    content: none;
}

.ladiui.create-key .dropdown-menu {
    min-width: 145px;
    width: 145px;
}

.ladiui.confirm-2 .modal-footer {
    margin-top: 20px;
}

.ladiui.funnel-text {
    margin-left: 50px;
}

.ladiui.funnel-text label {
    margin-left: 246px;
}

.ladiui.funnel-text label:first-child {
    margin-left: 0;
}

.ladiui.funnel-wrapper {
    display: flex;
}

.ladiui.group-input {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary-color);
    width: 712px;
    border-radius: 2px;
}

.ladiui.group-input .form-control {
    border: 1px solid var(--main-white);
    padding-left: 8px;
}

.ladiui.space-between {
    width: 2px;
    height: 20px;
    background-color: var(--secondary-color);
}

.ladiui.wrapper-group .ladiui-btn-tag {
    width: 34px;
    height: 34px;
    padding: 6px;
    text-align: center;
    margin-right: 8px;
}

.ladiui.sortable-line {
    margin: 8px 0;
    left: initial !important;
    top: initial !important;
    height: initial !important;
    width: initial !important;
}

body {
    counter-reset: section;
}

.ladiui.sortable-line .ladiui-btn-tag::before {
    counter-increment: section;
    content: counter(section);
}

.ladiui.sortable-line:first-child {
    margin-top: 0;
}

.ladiui.sortable-line:last-child {
    margin-bottom: 0;
}

.ladiui.first.form-control {
    width: 552px;
}

.ladiui.group-input .icon-ldp-drag {
    cursor: grab;
    pointer-events: all;
    margin-right: 10px;
}

.ladiui.sortable-line .icon-ldp-delete {
    cursor: pointer;
    pointer-events: all;
    margin-left: 10px;
}

.ladiui.funnel .proviso {
    padding: 16px 0 0 0;
}

.ladiui.funnel td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ladiui.funnel .col-first {
    width: 16px;
}

.ladiui.funnel .col-one {
    width: 470px;
}

/*----------------------------------------------------------------------------------------dynamic-report----------------------------------------------------------------------------------------*/
.ladiui.dynamic-report-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.ladiui.dynamic-report-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.dynamic-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary)
}

.ladiui.dynamic-span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-left: 8px;
}

.ladiui.dropdown-select-dynamic {
    margin-top: 10px;
}

.ladiui.btn-group.pull-right {
    margin-left: 24px;
}

.ladiui.dynamic-report-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 50px;
}

.ladiui.report-total-item {
    border: 1px solid var(--main-primary);
    border-radius: 10px;
    min-width: 188px;
    height: 106px;
}

.ladiui.report-total-item-lable {
    padding: 24px 0px 0px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.report-total-item-number {
    padding: 4px 0px 0px 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report-bg {
    border: 1px solid var(--main-line);
    margin-top: 50px;
    border-radius: 5px;
}

.ladiui.table.boder {
    margin-top: 0px;
}

.ladiui.table-vertical.header.item th {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.item td {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.end td {
    padding: 16px 16px 25px 16px;
}

.ladiui.search-action.custom {
    margin: 0px 16px 16px 16px;
}

.ladiui.report-convert {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 24px;
    margin-top: 24px;
}

.ladiui.report-convert-left {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-right {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin: 16px 0px 16px 0px;
}

.ladiui.report-convert-right {
    text-align: center;
}

.ladiui.report-convert-right img {
    margin-top: 32px;
}

/* ----------------------------------------------------------------------------------conversion-tool--------------------------------------------------------------------------------------- */
.ladiui.conversion-tab {
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.conversion-content-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin-bottom: 16px;
}

/*custom modal dynamic*/
.ladiui.modal .btn-group.custom-form-dynamic {
    width: 180px;
}

.ladiui.modal .btn-group .dropdown-toggle.custom-form-dynamic,
.ladiui.modal .btn-group .dropdown-menu.custom-form-dynamic {
    width: 180px;
}

.ladiui.item-form.sub1 {
    margin-left: 24px;
}

.ladiui.switch.custom-form-dynamic {
    margin: 0px;
}

.ladiui.item-form.custom-form-dynamic {
    margin-left: 42px;
}

.ladiui.title-text-form-lever2.custom-form-dynamic {
    margin-bottom: 16px;
}

.ladiui.item.flex-row.custom-form-dynamic {
    align-items: flex-start;
}

.ladiui.dropdown-menu.custom-dropdown-dynamic {
    width: 196px !important;
}

.ladiui.btn-group.col-2,
.ladiui.dropdown-toggle.col-2,
.ladiui.dropdown-menu.col-2 {
    width: 288px !important;
    margin-right: 16px;
}

.ladiui.itemss {
    justify-content: flex-start;
}

.ladiui.items-1 {
    width: 310px;
}

.ladiui.items-2 {
    position: relative;
    width: 280px;
    margin-left: 16px;
}

.ladiui.items-3 {
    width: 90px;
    margin-left: 16px;
}

.ladiui.items-4 {
    position: relative;
    width: 704px;
}

.ladiui.flex-row.custom-create-dynamic {
    margin-top: 4px;
}

.ladiui.modal-footer.custom-ldp-popupX.mod {
    padding-top: 36px;
}

.ladiui.infomation-link {
    margin-bottom: 19px;
    margin-left: 4px;
}

.ladiui.infomation-links {
    margin: 0px 0px 12px 4px;
}

.ladiui.dropdown-toggle.custom-main-tool.dynamic,
.ladiui.dropdown-menu.custom-dynamic {
    width: 320px !important;
    margin-left: 16px;
}

.ladiui.btn-group.custom-dynamic {
    width: 336px !important;
}

.ladiui.bg-form-custom.custom-dynamic {
    margin-top: 28px;
}

.ladiui.subtitle-2.custom-dynamic {
    margin-top: 8px;
    font-weight: 400;
    margin-left: 326px;
}

.ladiui.icon-add-input {
    position: absolute;
    top: 8px;
    right: 8px;
}

.ladiui.icon-add-input1 {
    position: absolute;
    top: 5px;
    right: 8px;
}

.ladiui.flex-row.custom-dynamic-s {
    text-align: center;
}

.ladiui.infomation-linkss {
    margin-left: 8px;
}

.ladiui.icon-search.custom {
    display: block;
    position: relative;
    left: 0px;
    margin: 18px 8px 2px 0px;
}

.ladiui.search-tag-fixed {
    align-items: baseline;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-mute);
    position: relative;
    margin-top: 12px;
}

.ladiui.inpud-search-tag-fixed {
    position: relative;
    bottom: 3px;
    width: 165px;
}

.ladiui.delete-right {
    margin-left: 16px;
}

.ladiui.dropdown-group-form {
    max-width: 744px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ladiui.dropdown-toggle.select-dropdown.col-3 {
    width: 280px !important;
}

.ladiui.btn-group.ctm {
    width: 196px !important;
}

.ladiui.btn-group.ctms {
    width: 280px !important;
    margin-left: 16px;
}

.ladiui.dropdown-menu.ctms {
    width: 280px !important;

}

.ladiui.btn-group.ctm.col2 {
    margin-left: 16px;
}

.ladiui.conversion.content-main {
    display: none;
}

.ladiui.conversion.main .tab-link {
    border-bottom: none;
    margin-left: 12px;
}

/* ---------------------------------------------------------------------------------------Tracking global --------------------------------------------------------------------------------------- */
.ladiui.nav-tracking {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link.active {
    border-bottom: 2px solid var(--main-primary);
}

.ladiui.tracking-area .ladiui.tab-content .tab-pane {
    margin-top: 0;
}

.ladiui.tracking-list .col-four {
    min-width: 200px;
    width: 200px;
}

.ladiui.tracking-list .col-first {
    min-width: 40px;
    width: 40px;
}

.ladiui.account-tracking img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

/* ------------------------------------------------------------------------------------------conversion-link-------------------------------------------------------------------------------------------- */
.ladiui.grid-link {
    width: 795px;
    height: 384px;
    display: grid;
    grid-template-columns: 263px 263px 263px;
    grid-template-rows: 136px 136px 136px 136px 136px 136px 136px 136px 136px 136px;
    overflow-y: auto;
}

.ladiui.link-box {
    cursor: pointer;
    width: 250px;
    height: 120px;
    border: 1px solid var(--main-line);
    border-radius: 2px;
}

.ladiui.link-box:hover {
    border: 1px solid var(--main-primary);
}

.ladiui.link-header {
    pointer-events: none;
    margin-bottom: 24px;
    padding: 16px 16px 0 16px;
}

.ladiui.link-header img {
    margin-right: 16px;
}

.ladiui.link-body {
    pointer-events: none;
    padding: 0px 16px 16px 16px;
    color: var(--main-secondary);
}

.ladiui.modal-header img {
    margin-right: 16px;
}

.ladiui.header-gmail {
    width: 36px;
    height: 24px;
}

.ladiui.header-ladisale {
    width: 32px;
    height: 32px;
}

.ladiui.flex.input .ladiui.form-group {
    width: 100%;
    margin: 24px 12px 0;
}

.ladiui.flex.input .ladiui.form-group:first-child {
    margin-left: 0;
}

.ladiui.flex.input .ladiui.form-group:last-child {
    margin-right: 0;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-toggle {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-menu {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .smtp.flex .btn-group {
    min-width: 100px;
    width: 100px;
}

.ladiui.smtp-host {
    width: 264px;
}

.ladiui.smtp-port {
    width: 140px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-toggle {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-menu {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .esms.flex .btn-group {
    min-width: 264px;
    width: 264px;
}

.ladiui.form-group .btn.convert {
    margin-top: 27px;
}

.ladiui.ggform-url .form-group .form-control {
    width: 450px;
}

.ladiui.form-field {
    margin-top: 16px;
    width: 100%;
}

.ladiui.form-field .icon {
    margin: 0 16px;
}

.ladiui.form-field .icon:last-child {
    margin-right: 0;
}

.ladiui.form-field .form-control {
    width: 252px;
}

.ladiui.form-field .btn-group {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-toggle {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-menu {
    min-width: 220px;
    width: 220px;
}

.ladiui.form-field .size-checkbox {
    margin: 0 12px 0 16px;
}

.ladiui.type-account {
    align-items: center;
    text-align: center;
}

.ladiui.type-account img {
    margin: 0 8px 0 16px;
    height: 24px;
    width: 24px;
}

.ladiui.type-account .custom-img {
    height: 16px;
    width: auto;
}

/* ----------------------------------------------------------------------------------Conversion-configuration--------------------------------------------------------------------------------------------- */
.ladiui.config-box .about {
    text-align: center;
    width: 190px;
    margin-top: 8px;
}

.ladiui.config .search-group {
    min-width: 270px;
}

.ladiui.config .step {
    margin: 16px 0 32px;
}

.ladiui.config .step:last-child {
    margin: 40px 0 0;
}

.ladiui.grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 350px auto auto;
    padding: 16px 0;
}

.ladiui.linking-form-group {
    padding-right: 20px;
    margin-right: -20px;
    max-height: 250px;
    overflow-y: auto;
}

.ladiui.setting-config .grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 300px auto auto;
    padding: 16px 0;
}

.ladiui.linking-form-group::-webkit-scrollbar {
    width: 3px;
}

.ladiui.linking-form-group .grid-linking-form {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.grid-linking-form .type-account {
    text-align: start;
}

.ladiui.grid-linking-form .type-account .account-tracking img {
    margin-left: 0;
    width: 16px;
    height: 16px;
}

.ladiui.grid-linking-form .select-button {
    text-align: end;
}

.ladiui.grid-linking-form .select-button .edit-icon {
    margin-right: 10px;
}

/*------------------------------------------------------------------------------------------template----------------------------------------------------------------------------------*/
.ladiui.content-main-tool.custom-template {
    margin-top: 0px;
}

.ladiui.theme-category {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.theme-category-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ladiui.theme-category-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.ladiui.custom-width-tab:hover>.theme-tag-list-box {
    display: block;

}

.theme-tag-list-box {
    display: none;
    position: absolute;
    top: 70px;
    width: 690px;
    z-index: 999;
    height: 380px;
    max-height: 380px;
}

.theme-border-box {
    display: flex;
    height: 100%;
    box-sizing: border-box;
}

.theme-flex {
    align-content: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 -24px;
    padding: 0;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    background-color: var(--main-white);
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 7%);
}

.ladiui.content-main-item-hover {
    display: inline-flex;
    writing-mode: horizontal-tb;
    width: 290px;
}

.ladiui.content-main-grid {
    min-width: 824px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ladiui.content-main-item.col4 {
    margin: 24px 24px 24px 24px;
}

.ladiui.content-main-item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 8px;
}

.ladiui.content-main-item-subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    margin-bottom: 8px;
    width: 250px;
}

.ladiui.theme-category-item.one:hover .ladiui.theme-category-text {
    color: var(--main-primary);
}

.ladiui.custom-width-tab {
    padding: 24px;
}

.ladiui.custom-width-tab-item {
    margin-left: 4px;
}

.ladiui.theme-category-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    pointer-events: none;
    color: var(--main-default);
}

.theme-category-text.active,
.ladiui.theme-category-text.active {
    color: var(--main-primary);
}

.ladiui.theme-category-item.tab-link {
    padding-bottom: 0px;
}

.ladiui.theme-category-item.tab-link.active {
    border: 0px;
}

.ladiui.content-tab-icon {
    pointer-events: none;
}

.ladiui.tab-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
    margin: 24px 0px 4px 0px;
}

.ladiui.tab-item-text-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin: 16px 0px;
}

.ladiui.custom-btn {
    padding: 6px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 2px;
    color: var(--main-black);
    background-color: var(--main-white);
    border: 1px solid var(--main-line);
    cursor: pointer;
}

.ladiui.custom-btn:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.custom-btn:active {
    color: var(--main-white);
    background-color: var(--main-primary);
}

.ladiui.custom-btn.active {
    color: var(--main-white);
    background-color: var(--main-primary);
    cursor: pointer;
}

.ladiui.group-item-main-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
}

.ladiui.custom-left-group-button {
    margin-left: 8px;
}

.ladiui.template-grid {
    width: calc(100% / 3 - 20px);
    height: 250px;
    border-radius: 2px;
    margin-top: 32px;
    position: relative;
    border: 1px solid var(--main-line);
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}

.ladiui.opacity-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    opacity: 0.09;
}

@media (max-width: 1371px) {
    .ladiui.template-grid {
        width: calc(100% / 2 - 20px);
        min-height: 290px;
        border-radius: 2px;
        margin-top: 32px;
        position: relative;
    }

    .ladiui.opacity-img {
        min-width: calc(100% / 2 - 20px);
        min-height: 250px;
    }

}

.ladiui.template-grid-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-top: 16px;
}

.ladiui.template-grid-type {
    position: absolute;
    top: 8px;
    left: 8px;
}

.ladiui.template-grid-type-hover {
    position: absolute;
    top: 8px;
    left: 8px;
    display: none;
}

.ladiui.template-grid-button {
    width: 100px;
    height: 36px;
    background-color: var(--main-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
}

.ladiui.group-button-template-grid {
    position: absolute;
    top: 98px;
    left: calc((100% - 216px) / 2);
    display: none;
}

.ladiui.template-grid:hover .ladiui.group-button-template-grid {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type-hover {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type {
    display: none;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    background-color: var(--main-white);
}

.ladiui.template-grid-button {
    cursor: pointer;
    margin: 8px;
}

.ladiui.template-grid-button:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.content-main-item-subtext:hover {
    color: var(--main-primary);
}

.ladiui.template-btn-show-more {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.show-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    cursor: pointer;
}

.ladiui.template-btn-show-more {
    margin-bottom: 6px;
}

.ladiui.banner-ads-grid.showcase {
    margin-top: 0px;
}

.ladiui.modal-dialog.showcase {
    width: 824px;
    text-align: center;
    box-shadow: 0px 3px 15px rgba(169, 184, 200, 0.15);
    border-radius: 10px;
}

.ladiui.modal-footer.showcase {
    justify-content: center;
}

.ladiui.show-case-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
}

.ladiui.show-case-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 31px 0px 48px 0px;
}

/*code new*/

.ladiui.number-notificiation {
    min-width: 16px;
    height: 16px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    top: -2px;
    right: -4px;
}

.ladiui.number-notificiation-text {
    color: var(--main-white);
    background-color: red;
    border-radius: 10px;
    padding: 1px 4px;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    position: absolute;
    top: 3px;
    left: 0;
}

.ladiui.bin {
    margin-left: 18px;
}

.fixel-s {
    position: relative;
}

.ladiui.show-down {
    margin-top: 10px !important;
}

.ladiui.custom-date-dynamic {
    width: 232px !important;
}

.ladiui.new-page-content {
    pointer-events: none !important;
}

.ladiui.number-notificiation {
    pointer-events: none;
}

.ladiui.link.subtitle-2.custom-link {
    vertical-align: baseline;
    margin: 0px 4px;
}

.ladiui.dynamic-type2 {
    margin-top: 32px;
}

.ladiui.search-group.custom-nav-bar {
    padding: 16px 16px 8px 16px;
}

.ladiui.search-field.form-control.custom-header {
    position: relative;
    padding: 8px;
}

.ladiui.logo-item.logo-hover {
    cursor: pointer;
}

.ladiui.link.subtitle-2 {
    margin: 0px 4px;
    vertical-align: bottom;
}

.ladiui.name-noti {
    color: var(--default-color);
    vertical-align: bottom;
    margin-right: 4px;
}

.ladiui.dropdown-menu.custom-header {
    min-width: 200px;
    min-height: 150px;
}

.ant-select-dropdown {
    z-index: 900000000 !important;
}

.ant-select-multiple .ant-select-selection-item-content {
    margin-right: 8px !important;
}

.ant-select-multiple .ant-select-selection-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--default-color);
    background-color: rgba(241, 243, 244, 1) !important;
}

.ant-select-selection-placeholder {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: var(--main-mute) !important;
}

.ant-select-selector {
    border: 1px solid var(--main-line) !important;

}

.ladiui.form-control::placeholder {
    color: var(--main-mute);
}

.weight-bg {
    font-weight: 500 !important;
}

.width-about {
    min-width: 150px !important;
    justify-content: center !important;
}

.ladiui.number-showing {
    margin: 0px 4px;
}

.ladiui.icon.icon-search.topbars {
    position: absolute;
    top: 20px;
    right: 24px;
}

.ladiui.subtitle-3 {
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.body-8 {
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 26px;
}

.ladiui.dropdown-item.account-body-item {
    display: flex;
}

.ladiui.introduce-text {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.ladiui.introduce-img {
    margin-bottom: 36px;
}

.ladiui.modal-dialog.custom-modal-domain {
    width: 600px !important;
}

.ladiui.custom-text-link {
    vertical-align: baseline;
}

.ladiui.number-select {
    margin: 0 4px;
}

.ant-pagination-item-link,
.ant-pagination-item {
    border: none !important;
}

.ant-pagination-item:hover a {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--main-black) !important;
}

.ant-pagination-item-active a {
    color: var(--main-black) !important;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1);
}

.ant-pagination-item-ellipsis {
    top: 8px !important;
}

.ant-pagination-options {
    display: none !important;

}

.anticon svg {
    cursor: pointer;
}

.dropdown-toggle.custom-header,
.dropdown-toggle.select-dropdown {
    cursor: pointer;
}

.ladiui.search-landing-tag {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 24px;
    color: var(--main-black);
}

.ladiui.icon.icon-search.fix-tag {
    position: absolute;
    top: 2px;
    left: 0px;
    z-index: 9998;
    margin: 0px;
}

.ladiui.showing {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default)
}

.ladiui.name-landingpage {
    max-width: 200px;
    overflow-wrap: break-word;
}

.ladiui.dropdown-toggle.selected {
    position: relative;
}

.ladiui.col-one {
    position: relative;
}

.ladiui.selected {
    position: absolute;
    top: -3.5px;
    display: none;
}

.ladiui-btn-tag.tags-list.active {
    background-color: var(--main-primary);
    color: var(--main-white);
    cursor: pointer;
}

.ladiui.custom-page-list {
    margin-bottom: 0;
    right: 24px;
}

.ladiui.dropdown-toggle.custom-page-lists::after {
    display: none;
}

.ladiui.col-one.setting {
    min-width: 204px;
}

.ladiui.col-tow.setting {
    min-width: 189px;
}

.ladiui.col-three.setting {
    min-width: 162px;
}

.ladiui.col-for.setting {
    min-width: 212px;
}

.ladiui.col-five.setting {
    min-width: 190px;
}

.ladiui.dropdown-toggle.custom-create-tag {
    width: 100%;
}

.ladiui.dropdown-toggle.custom-create-tags {
    width: 165px;
}

.ladiui.dropdown-toggle.custom-create-tag::after {
    margin: 14px 40px 14px 16px;
}

.ladiui.dropdown-menu.custom-create-tag {
    width: 552px;
}

.ladiui.dropdown-menu.custom-create-tags {
    width: 165px;
}

.ladiui.btn.custom-create-tag {
    height: 36px
}

.ant-select-multiple .ant-select-selector {
    padding: 2px 4px !important;
}

.builder-loading {
    top: 64px;
    left: 75px;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: calc(100% - 75px);
    height: calc(100% - 64px);
    position: fixed;
    background-size: 5%;
    overflow: hidden;
    z-index: 999;
    background-size: 128px;
}

.builder-loading-custom {
    top: 64px;
    left: 313px;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: calc(100% - 313px);
    height: calc(100% - 64px);
    position: fixed;
    background-size: 5%;
    overflow: hidden;
    z-index: 999;
    background-size: 128px;
}

.builder-loading-blur {
    background-color: rgba(12, 97, 242, .05);
}

.ladiui.template-grid-button a {
    color: var(--main-white)
}

.ladiui.form-control.custom:focus-within {
    border: 1px solid var(--main-primary);
}

.ladiui.form-control.custom {
    display: block;
    width: 100%;
    padding: 6.3px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-black);
    background-color: var(--main-white);
    background-clip: padding-box;
    border: 1px solid var(--main-line);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
}

.ladiui-tag-list .tag {
    position: relative;
    float: left;
    background-color: var(--secondary-color);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-right: 8px;
    margin-bottom: 5px;
    padding: 2px 28px 2px 8px;
    border-radius: 2px;
}

.ladiui-tag-list .tag .close {
    position: absolute;
    top: 6px;
    right: 8px;
    line-height: 19px;
    cursor: pointer;
}

.ladiui-tag-list.tag .close::before {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    background-color: var(--secondary-color);
}

.ladiui.input.custom-input-autocomplete {
    /* height: 14px; */
    width: 100%;
}

.clearfix {
    clear: both
}

.async-auto-complete.menu {
    width: 100%;
    margin-top: 10px;
    z-index: 999999;
    max-height: 175px !important;
    min-height: 125px !important;
    min-width: 552px !important;
    left: 0;
    margin-left: -13px;
}

.tag-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-black);
}

.item {
    max-width: 526px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.item {
    padding: 8px 16px;
}

.ladiui.dropdown-menu.custom-teamplate {
    min-width: 270px !important;
}

.ladiui.custom-btn.template {
    padding: 6px 8px;
}

.ladiui.form-group.custom-decentralization {
    border-collapse: collapse;
    border-spacing: 0;
    max-height: 300px;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 1px;
    background-color: #fff;
}

.ladiui.dropdown-menu.custom-dropdown-menu.sub-tag {
    border-collapse: collapse;
    border-spacing: 0;
    max-height: calc(62vh - 222px);
    overflow-y: auto;
    overflow-x: hidden;
}

.ladiui.dropdown-menu.customer {
    border-collapse: collapse;
    border-spacing: 0;
    max-height: calc(62vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
}

.ladiui.dropdown-menu.custom-dropdown-dynamic {
    border-collapse: collapse;
    border-spacing: 0;
    max-height: calc(62vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
}


.ladiui.dropdown-menu.search-width {
    border-collapse: collapse;
    border-spacing: 0;
    max-height: calc(62vh - 222px);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999;
}

.ladiui.dropdown-menu.custom-people {
    border-collapse: collapse;
    border-spacing: 0;
    max-height: calc(62vh - 285px);
    overflow-y: auto;
    overflow-x: hidden;
}

.ladiui.dropdown-menu.custom-header {
    border-collapse: collapse;
    border-spacing: 0;
    max-height: calc(62vh - 222px);
    overflow-y: auto;
    overflow-x: hidden;
}

.ladiui.modal.remove-member {
    display: none;
}

.ladiui.default-role {
    padding: 6px 12px;
    border: 1px solid var(--main-line);
    margin: 0;
}

.ladiui.btn-group.custom-setting {
    padding: 0;
    margin: 0;
    width: 100%;
}

.ladiui.custom-setting {
    width: 100%;
}

.ladiui.dropdown-menu.custom-setting {
    border-collapse: collapse;
    border-spacing: 0;
    max-height: calc(62vh - 222px);
    overflow-y: auto;
    overflow-x: hidden;
}

.ladiui.modal-body.custom-table {
    position: relative;
}

.ladiui.custom-table-modal {
    width: 100%;
    z-index: 1;
    overflow-x: hidden;
    max-height: calc(80vh - 157px);
}

.ladiui.dropdown-menu.custom-people.fix {
    left: 14px;
}

.ladiui.select-search.btn.fix-setting {
    min-width: 255px;
}

.ladiui.pagination {
    position: absolute;
    right: 20px;
}

.ladiui.pagination img {
    width: 16px;
    height: 16px;
}

.ladiui.grid-role {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-column-gap: 45px;
}

.ladiui.flex-rolr-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
}

.ladiui.flex-rolr-item-text {
    margin-left: 8px;
}

.ladiui.title-role {
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.ladiui.customFix {
    width: 466px !important;
}

.ladiui.customFixs {
    margin-left: 16px;
}

.ladiui.line-strocker {
    margin-top: 24px;
    border: 1px solid var(--secondary-color);
}

.ladiui.fix-setting {
    margin-left: 12px;
}

.ladiui.custom-settings {
    margin-left: 24px;
    min-width: 188px;
}

.ladiui.fix-settinga {
    min-width: 188px !important;
}

.ladiui.page-container a.active {
    background-color: rgba(0, 0, 0, .1);
    border-radius: 2px;
    cursor: default;
}

.ladiui.flex-between {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.ladiui.modal-footer.confirm {
    justify-content: flex-end !important;
}

.ladiui.dropdown-menu {
    z-index: 999;
}

.ladiui.dropdown-menu.custom-modal {
    right: 24px;
}

.ladiui.form-control.custom-modal {
    text-transform: uppercase !important;
}

.ldp-publish-time i {
    margin-right: 8px;
}

::-webkit-input-placeholder {
    text-transform: none !important;
}

:-ms-input-placeholder {
    text-transform: none !important;
}

.ladiui.custom-modalsub {
    width: 450px !important;
}

.ladiui.dropdown-toggle.custom-modals {
    width: 120px !important;
}

.link-unpublish {
    color: #AFAFAF
}

.tag-selected-remove-all {
    position: absolute;
    right: 28px !important;
}

.DateInput_input__small {
    font-size: 14px;
    line-height: 20px !important;
    font-weight: 400 !important;
    padding: 6px 12px;
}

.DateRangePickerInput__showClearDates {
    height: 36px !important;
}

.DateRangePickerInput_calendarIcon {
    padding: 6px 12px !important;
    margin: 0px 0px 0px 5px;
}

.ladiui.select-search.dropdown.components {
    margin-left: 24px;
}

.ladiui.component {
    position: relative;
}

.ladiui.searchStaff {
    border-collapse: collapse;
    border-spacing: 0;
    max-height: calc(62vh - 222px);
    overflow-y: auto;
    overflow-x: hidden;
}

.ladiui.dropdown-menu.custom-dropdown-menu {
    text-align: left;
}

.ladiui.box-statistics {
    border-radius: 2px;
    height: 94px;
    border: 1px solid #f1f3f4;
    width: calc(25% - 18px);
    margin: 12px 6px;
    padding: 16px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: inherit;
}

.ladiui.cursor-custom {
    cursor: pointer;
}

.ladiui.box-statistics:first-child {
    margin-left: 0px;
}

.ladiui.box-statistics:last-child {
    margin-right: 0px;
}

.ladiui.box-statistics .box-statistics-icon {
    border-radius: 50%;
    background: #f1f3f4;
    width: 48px;
    height: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.box-report {
    margin-bottom: 48px;
}

.ladiui.custom-dynamic-modal {
    margin-bottom: 8px;
}

.ladiui.nav::-webkit-scrollbar {
    width: 0px;
}

#input-create-ladipage-file {
    display: none;
}

.ladiui.template-preview {
    background-color: white;
    margin-left: -74px;
    width: auto;
    border: 1px solid var(--main-line);
}

.ladiui.navigation.preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
}

.ladiui.view.theme {
    display: flex;
    flex-direction: row;
    grid-gap: 26px;
}

.ladiui.view.theme img {
    cursor: pointer;
}

.theme-preview-iframe {
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: calc(100% - 50px);
    margin: 0 auto;
    left: 0;
    right: 0;
}

.ladiui.show-giude {
    padding: 8px 24px 32px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-mute)
}

.ladiui.modal-body.custom {
    flex: 1;
    margin-bottom: 0px !important;
    padding: 24px 24px 0;
    position: relative;
}

.ladiui.icon-ldp-black-list-ip {
    pointer-events: none;
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladipage/icon-black-list.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladipage/icon-black-list.svg) no-repeat 50% 50%;
}

.ladiui.icon-mobile-view {
    pointer-events: none;
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladipage/icon-preview-mobile.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladipage/icon-preview-mobile.svg) no-repeat 50% 50%;
}

.ladiui.icon-password-view {
    mask-size: 20px !important;
    pointer-events: unset !important;
    cursor: pointer !important;
    width: 20px;
    height: 20px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-eye.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-eye.svg) no-repeat 50% 50%;
    float: right;
    margin-right: 10px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
}

.ladiui.icon-password-hide {
    mask-size: 20px !important;
    pointer-events: unset !important;
    cursor: pointer !important;
    width: 20px;
    height: 20px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-eye-closed.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-eye-closed.svg) no-repeat 50% 50%;
    float: right;
    margin-right: 10px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
}

.ladiui.icon-web-view {
    pointer-events: none;
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladipage/icon-preview-monitor.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladipage/icon-preview-monitor.svg) no-repeat 50% 50%;
    background-color: #1C00C2 !important;
}

.ladiui.icon-not-foder {
    width: 80px;
    height: 80px;
    padding: 12px;
    display: block;
    margin: auto;
    /*Test*/
}

.ladiui.search-field.custom-dropdown {
    margin: 0px 16px 8px 16px;
}

.ladiui.modal .btn-group .dropdown-toggle.custom-dropdown {
    width: 520px;
}

.ladiui.dropdown-menu.custom-dropdown i {
    right: 26px !important;
}

.ladiui.show-text-public {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
    margin-top: 14px;
}

.ladiui.table.text-left.fix-header.customer {
    margin-top: 0px;
}

.ladiui.flex-between.custom-table-header.custom {
    padding-bottom: 25px;
}

.ladiui.dropdown-menu.custom-dropdown-menu.customer {
    min-width: 160px !important;
}

.ladiui.show-password {
    position: absolute;
    right: 38px;
    bottom: 8px;
}

.ladiui.hide-password {
    position: absolute;
    right: 38px;
    bottom: 8px;
    display: none;
}

.ladiui.custom-dropdown-modal.fixel {
    margin: 14px -45px 14px 16px !important;
}

.ladiui-btn-tag.ladiui-dropdown {
    margin-top: 8px;
}

.ladiui.modal-header.custom-ldp.alertsv {
    padding: 0px 0px 8px 0px;
}

.ladiui.text.alertsv {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.ladiui.btn.btn-primary.center {
    text-align: center;
}

.ladiui.modal-footer.custom-ldp1.alertsv {
    justify-content: center !important;
}

#showAction {
    display: none;
}

.ladiui.ladiui.wrapper.lang-th .ladiui.table-vertical th,
.ladiui.ladiui.wrapper.lang-th .ladiui.content-tag-text {
    font-weight: 600;
}

.ladiui.main-content {
    word-wrap: break-word;
    background-color: var(--main-white);
    border-right: 1px solid var(--main-line);
    height: 100%;
}

.ladiui.icon-menu-dashboard {
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-dashboard.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-dashboard.svg) no-repeat 50% 50%;
    box-sizing: unset;
    display: inline-block;
    height: 24px;
    pointer-events: none;
    width: 24px;
}

.ladiui.icon-menu-partner {
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-partner.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-partner.svg) no-repeat 50% 50%;
    box-sizing: unset;
    display: inline-block;
    height: 24px;
    pointer-events: none;
    width: 24px;
}

.ladiui.icon-menu-user {
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-user.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-user.svg) no-repeat 50% 50%;
    box-sizing: unset;
    display: inline-block;
    height: 24px;
    pointer-events: none;
    width: 24px;
}

.ladiui.icon-menu-order {
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-order.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-order.svg) no-repeat 50% 50%;
    box-sizing: unset;
    display: inline-block;
    height: 24px;
    pointer-events: none;
    width: 24px;
}

.ladiui.icon-menu-payment {
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-payment.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladiapp/icons/icon-menu-payment.svg) no-repeat 50% 50%;
    box-sizing: unset;
    display: inline-block;
    height: 24px;
    pointer-events: none;
    width: 24px;
}


.ladiui-group-credit ul li .ladiui-text-credit span {
    color: #e89d35;
    margin: 0 0 0 8px;
}

.ladiui-group-box {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(4, 1fr);
    margin: 70px 0 0 0;
}

.ladiui-group-box .ladiui-group-items {
    background: #ffffff;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    padding: 24px;
}

.ladiui-group-box .ladiui-group-items img {
    width: 143px;
}

.ladiui-group-box .ladiui-group-items .ladiui-text-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding: 24px 0 16px;
}

.ladiui-group-box .ladiui-group-items .ladiui-box-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ladiui-group-box .ladiui-group-items .ladiui-box-button .ladiui-button-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1c00c2;
}

.ladiui-group-box .ladiui-group-items .ladiui-box-button .ladiui-button-text-url {
    position: relative;
    background: #1c00c2;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 8px 6px;
    width: 94px;
    height: 32px;
}

.ladiui-group-box .ladiui-group-items .ladiui-box-button .ladiui-button-text-url::before {
    position: absolute;
    font-size: 11px;
    right: 10px;
}

.ladiui-group-box .ladiui-group-items .ladiui-box-button .ladiui-button-text-url span {
    font-family: "Roboto";
    margin: 0 0 0 2px;
}

.credit-info ul li {
    display: inline-block;
    margin: 24px;
}

.pd24 {
    padding: 24px
}

.has-submenu {
    left: 236px;
    position: absolute;
    width: calc(100% - 236px);
}

.action-item {
    display: inline-flex;
    margin-right: 12px;
}

.tab-nav-item {
    display: inline-flex;
    margin-right: 24px;
}

.ladiui.tab-pane {
    margin-top: 20px;
}

.login-facebook img {
    position: relative;
    /* top: -2px; */
    padding: 0px 8px;
}

.login-facebook span {
    position: relative;
    font-family: "Roboto";
    /* font-weight: 500; */
}

.login-google img {
    position: relative;
    padding: 0px 8px;
}

.login-google span {
    position: relative;
}

.invoice-detail-summary>span {
    margin: 40px;
}

.invoice-detail-summary>span>strong {
    font-weight: 500;
}

.ladiui.show-avata-name.custom {
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: var(--main-primary);
    color: #fff;
    line-height: 40px;
    text-align: center;
}

.ladiui.auth .language {
    /* position: absolute;
    top: 19px;
    right: 0px; */
}

.ladiui-page-auth.no-iframe .ladiui.auth {
    width: 100%;
    display: inherit;

}

.ladiui-page-auth.no-iframe .ladiui.auth .ladiui-login-wrap {
    margin: 0px auto;
    padding-top: 96px;
    width: unset;
}

.ladiui-page-auth.no-iframe .ladiui.auth .auth-form-content,
.ladiui-page-auth.no-iframe .ladiui.auth .ladiui.auth .ladiui-logo {
    margin-top: unset;
}


@media screen and (max-width: 1023px) {
    .ladiui-iframe {
        display: none;
    }

    .ladiui.auth .ladiui-login-wrap {
        width: 100%
    }
}

@media screen and (max-width: 500px) {
    #policy-text {
        width: 320px !important;
    }

    .ladiui.auth .ladipage-terms {
        padding: 0px 12px;
        margin-bottom: 16px;
        width: unset;
    }

    .col-50 {
        width: unset !important;
        margin-top: 10px;
    }

    .ladiui.auth .create-user-form.signup .ladiui-form-group:not(.allow-policy) {
        display: block !important;
    }

    .ladiui.auth .ladiui-form-group.allow-policy {
        display: flex;
    }

    .ladiui.auth .ladiui-login-wrap {
        width: 100%
    }

    .ladiui.auth .ladiui-login {
        /* padding: 16px; */
        display: inherit;
        /* max-width: 420px; */
        margin: auto;
        /* height: 100vh; */
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-bottom: 90px;
    }

    #close-login-frame {
        top: 10px !important;
        right: 100px !important;
    }

    .ladiui.auth .ladipage-terms ul {
        white-space: nowrap;
    }

    .ladiui.auth .ladipage-terms ul li a.terms-item {
        padding: 0px 6px;
    }

    .ladiui.auth .ladiui-login-form {
        width: 100%;
    }

    .language .ladiui.dropdown-menu {
        right: calc(50vw - 78px);
        width: 154px;
    }

    .ladiui.auth .language {
        /* display: none; */
    }

    .create-user-form .ladiui-form-group {
        display: block;
        width: calc(100vw - 104px);
    }

    .user-field {
        width: 100%;
        margin-bottom: 10px;
    }

    .social-button {
        display: block !important;
    }

    .ladiui.auth .ladiui-login-form {
        width: calc(100vw - 104px);
    }

    .ladiui-page-auth {
        overflow-x: hidden;
    }

    .ladiui.auth .ladiui-login {
        width: calc(100% - 40px);
        /* padding: 32px; */
        margin: 0px 20px;
    }


    .ladiui.auth .social-login a.login-facebook,
    .ladiui.auth .social-login a.login-google {
        margin-bottom: 10px;
        width: calc(100vw - 104px);
    }

    .ladiui.auth span.login-with:after {
        width: 24px;
        right: 32px;
    }

    .ladiui.auth span.login-with h1:before {
        width: 24px;
        left: 32px;
    }

    .ladiui.auth .auth-form-content {
        margin-top: 48px;
    }

    .ladiui.auth .ladiui-login-wrap .form-title {
        margin-bottom: 24px;
    }

    .allow-policy input {
        top: 2px !important;
    }
}

.api-key-action {
    position: absolute;
    bottom: 4px;
    right: 12px;
    cursor: pointer;
}

#myTabContent {
    margin-top: 16px;
}

.ladi-popupx-inline {
    width: 100%;
    /* height: 50px; */
    display: block;
}

iframe.ladi-popupx {
    width: 100% !important;
}


.error-password.is_error {
    margin-top: 28px;
    color: rgba(234, 31, 35, 1);
    padding: 8px 12px;
    background-color: rgba(234, 31, 35, 0.2);
    border-radius: 6px;
}

.error-password {
    /* margin-top: 12px; */
}

iframe.ladi-popupx[data-ladipage-id="65265997226e580012ecf4fc"][data-element-id="POPUP1_DangKy"] {
    right: 10px !important;
    top: 10px !important;
    width: auto !important;
}

body.iframe-popupx iframe.ladi-popupx[data-ladipage-id="65265997226e580012ecf4fc"][data-element-id=POPUP1_DangKy] {
    display: none !important;
}

.allow-policy {
    display: flex;
}

.allow-policy input {
    width: 24px;
    position: relative;
    margin-right: 5px;
    top: -9px;
}

.verify-to {
    font-weight: 500;
}

.intl-tel-input,
.intl-tel-input .country-list {
    width: 100%;
    /* overflow-x: hidden; */
    z-index: 10 !important;
}

.intl-tel-input .country-list {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%) !important;
    border-radius: 4px !important;
    border: unset !important;
}

.intl-tel-input .flag-container .arrow.down {
    background-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down-fill.svg);
    width: 12px;
    height: 12px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 2px !important;
}

.intl-tel-input .flag-container .arrow.down:after,
.intl-tel-input .flag-container .arrow.up:after {
    content: unset !important;
}

.intl-tel-input .flag-container .arrow.up {
    background-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-up-fill.svg);
    width: 12px;
    height: 12px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 2px !important;
}